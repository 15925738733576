import { IRootState } from "@/main";
import { createToast, ToastType } from "mosha-vue-toastify";
import { ActionContext, Module } from "vuex";

interface IAlertState {
  loading: boolean;
}
interface IAlert {
  title: string;
  message: string;
  type: ToastType;
}
export const alertModule: Module<IAlertState, IRootState> = {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    // create alert
    async createAlert(
      context: ActionContext<IAlertState, IRootState>,
      alert: IAlert
    ) {
      createToast(
        {
          title: alert.title,
          description: alert.message,
        },
        { type: alert.type, showIcon: true }
      );
    },
  },
};
