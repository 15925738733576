<template>
  <button
    @click="onClick"
    :class="styleClass"
    :disabled="disabled"
    class="
      bg-primary
      text-gray-100 text-sm
      rounded-md
      disabled:opacity-50 disabled:cursor-not-allowed
      font-semibold font-display
      focus:outline-none focus:shadow-outline
      hover:bg-primary-dark
      shadow-md
      flex
      items-center
    "
  >
    <slot name="icon" />
    <slot />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    href: {
      type: String,
      default: "",
      required: false,
    },
    padding: {
      type: String,
      default: "p-4",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const styleClass = `${props.padding}`;
    const onClick = () => {
      emit("click");
    };
    return { onClick, styleClass };
  },
});
</script>
