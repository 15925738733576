import axios, { AxiosError } from "axios";
import { Quiz, QuizResultsResponse } from "./AdminService";
import ApiDataService, { License, UserPagination } from "./ApiDataService";
import UserService, { User } from "./UserService";

class EmployeeService {
  async fetchAllEmployees(
    company_id: number,
    page: number,
    sort: number
  ): Promise<UserPagination> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getAllEmployees(
      company_id,
      token,
      page,
      sort
    );
    return response.data;
  }

  async fetchAllEmployeesWithoutPaging(company_id: number): Promise<User[]> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getAllEmployeesWithoutPaging(
      company_id,
      token
    );
    return response.data;
  }

  async fetchAllLicenses(): Promise<License[]> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getAllLicenses(token);
    return response.data;
  }

  async fetchAllEmployeesByLicense(
    company_id: number,
    hashKey: string,
    page: number,
    sort: number
  ): Promise<UserPagination> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getAllEmployeesByLicense(
      company_id,
      hashKey,
      token,
      page,
      sort
    );

    return response.data;
  }

  async verifyUser(userId: number): Promise<[boolean, string | null]> {
    const token: string = UserService.getAccessToken();
    // const response = await ApiDataService.verifyUser(userId, token);
    // return response.data;
    try {
      await ApiDataService.verifyUser(userId, token);
      return [true, null];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return [false, error.response?.data]
      } else {
        return [false, null];
      }
    }
  }

  async deleteUser(userId: number): Promise<boolean> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.deleteUser(userId, token);
    return response.data;
  }

  async searchUser(keyword: string): Promise<User[]> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.searchUser(keyword, token);
    return response.data;
  }

  async getLicenseByHash(keyHash: string): Promise<License> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getLicenseByHash(keyHash, token);
    return response.data;
  }

  async getQuizzesForUserResults(userId: number): Promise<QuizResultsResponse[]> {
    const token: string = UserService.getAccessToken();
    const response = await ApiDataService.getQuizzesForUserResults(
      userId,
      token
    );
    return response.data;
  }
}

export default new EmployeeService();
