import http from "@/modules/http-common";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { License, QuizPagination, QuizResponse } from "./ApiDataService";
import UserService from "./UserService";

class AdminService {
  public getAllLicenses(): Promise<AxiosResponse<License[]>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<License[]>("licenses", config);
    return res;
  }

  public getAllQuizzes(
    page: number,
    licenseFilter: number,
    searchQuery: string,
  ): Promise<AxiosResponse<QuizPagination>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<QuizPagination>(
      `admin/quizzes/all?page=${page}&license=${licenseFilter}&search=${searchQuery}`,
      config
    );
    return res;
  }

  public createQuiz(quiz: any): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const data: string = JSON.stringify(quiz);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.post<string>("admin/createquiz", data, config);
    return res;
  }

  public uploadFile(file: File, id: number): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("attachment", file);

    const res = http.post<string>("admin/upload/" + id, formData, config);
    return res;
  }

  public updateQuizFile(
    id: number,
    filename: string
  ): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const res = http.post<string>(
      "admin/quiz/updatefile",
      {
        quiz_id: id,
        filename: filename,
      },
      config
    );
    return res;
  }

  public getQuestions(quizId: number): Promise<AxiosResponse<Question[]>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<Question[]>(`admin/quiz/${quizId}/questions`, config);
    return res;
  }

  public getAnswers(questionId: number): Promise<AxiosResponse<Answer[]>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<Answer[]>(
      `admin/question/${questionId}/answers`,
      config
    );
    return res;
  }

  public deleteQuiz(quizId: number): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.delete<string>(`admin/quiz/${quizId}`, config);
    return res;
  }

  public async downloadCertificate(userId: number, year: number): Promise<AxiosResponse> {
    const token = UserService.getAccessToken();
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer',
    };
    
    try {
      return http.get(`admin/cert/${userId}/${year}`, config);
    } catch (e) {
      throw e;
    }
  }
}

export interface Quiz {
  id: number;
  quiz_id?: number;
  questions: Question[];
  quiz_questions?: Question[];
  license_ids: number[];
  group_ids: number[];
  title: string;
  author: string;
  islandscape?: boolean;
  filename?: string;
  created_at: string;
  read_only: boolean;
  notification_enabled: boolean;
  notification_title?: string;
  notification_text?: string;
  company_id?: number;
  quizzes?: Quiz;
  quiz_assets?: QuizAsset[];
}

export interface QuizAsset {
  id: number;
  quiz_id: number;
  links: string;
  created_at: string;
}

export interface QuizResult {
  id: number;
  user_id: number;
  quiz_id: number;
  created_at: string;
  updated_at: string;
}

export interface QuizResultsResponse {
  quiz: Quiz;
  result: QuizResult;
}

export interface Question {
  id?: number;
  question: string;
  answers: Answer[];
  rightAnswer: string;
}

export interface Answer {
  id?: number;
  answer: string;
  right_answer?: boolean;
}

export default new AdminService();
