<template>
  <transition
    enter-active-class="duration-700 ease-out"
    enter-from-class="transform opacity-0 scale-75"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-75"
    appear
  >
    <div v-if="show" class="flex flex-col py-4 space-y-4 z-10">
      <div class="bg-transparent text-center lg:px-4" id="info">
        <div
          class="
            p-2
            bg-background-mat-primary
            shadow-md
            items-center
            text-white
            leading-none
            lg:rounded-full
            flex
            lg:inline-flex
          "
          role="alert"
        >
          <span
            class="
              flex
              rounded-full
              bg-yellow-100
              text-black
              uppercase
              px-2
              py-1
              text-xs
              font-bold
              mr-3
              tracking-wider
            "
            ><slot name="title"
          /></span>
          <p class="font-light mr-2 text-left flex-auto flex flex-col">
            <span><slot /></span>
          </p>
          <span
            v-if="hasActionSlot"
            class="
              flex
              rounded-full
              bg-primary
              text-white
              uppercase
              px-2
              py-1
              text-xs
              font-bold
              mr-3
              tracking-wider
            "
            ><slot name="action"
          /></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { slots }) {
    const hasActionSlot = !!slots["action"];
    return { hasActionSlot };
  }
});
</script>
