<template>
  <nav
    class="
      flex
      items-center
      justify-between
      flex-wrap
      bg-white
      shadow-md
      p-6
      w-full
      z-50
    "
  >
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <router-link :to="{ name: 'home' }">
        <!-- <span class="font-semibold text-xl tracking-tight">1Minute</span> -->
        <img src="../../assets/logo.png" class="h-8" />
      </router-link>
    </div>
    <div class="block lg:hidden">
      <button
        class="
          flex
          items-center
          px-3
          py-2
          border
          rounded
          text-teal-200
          border-teal-400
          hover:text-white
          hover:border-white
        "
      >
        <svg
          class="fill-current h-3 w-3 text-black"
          @click="menuIconToggle"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <div
      v-if="user?.role?.name != 'admin'"
      :class="{ hidden: !showMobileMenu }"
      class="
        w-full
        block
        flex-grow
        lg:flex
        lg:items-center
        lg:w-auto
        text-black
      "
    >
      <div class="text-sm lg:flex-grow space-x-4">
        <router-link to="/dashboard" class="menu-item">
          <a
            class="
              block
              mt-4
              lg:inline-block
              lg:mt-0
              text-teal-200
              hover:text-white
              hover:bg-primary-dark
              p-2
              rounded-lg
            "
          >
            Dashboard
          </a>
        </router-link>
        <router-link to="/employees" class="menu-item">
          <a
            class="
              block
              mt-4
              lg:inline-block
              lg:mt-0
              text-teal-200
              hover:text-white
              hover:bg-primary-dark
              p-2
              rounded-lg
            "
          >
            Mitarbeiter verwalten
          </a>
        </router-link>
        <router-link to="/bonuses" class="menu-item">
          <a
            class="
              block
              mt-4
              lg:inline-block
              lg:mt-0
              text-teal-200
              hover:text-white
              hover:bg-primary-dark
              p-2
              rounded-lg
            "
          >
            Prämien verwalten
          </a>
        </router-link>
        <router-link to="/licenses" class="menu-item">
          <a
            class="
              block
              mt-4
              lg:inline-block
              lg:mt-0
              text-teal-200
              hover:text-white
              hover:bg-primary-dark
              p-2
              rounded-lg
            "
          >
            Abonnement
          </a>
        </router-link>
        <router-link to="/settings/password" class="menu-item">
          <a
            class="
              block
              mt-4
              lg:inline-block
              lg:mt-0
              text-teal-200
              hover:text-white
              hover:bg-primary-dark
              p-2
              rounded-lg
            "
          >
            Einstellungen
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{ name: 'logout' }"
          class="
            inline-block
            text-sm
            px-4
            py-2
            leading-none
            border
            rounded
            text-black
            border-white
            hover:border-transparent
            hover:text-teal-500
            hover:bg-gray-50
            hover:text-black
            mt-4
            lg:mt-0
          "
          >Abmelden</router-link
        >
      </div>
    </div>
    <div
      v-else
      :class="{ hidden: !showMobileMenu }"
      class="
        w-full
        block
        flex-grow
        lg:flex
        lg:items-center
        lg:w-auto
        text-black
      "
    >
      <div class="text-sm lg:flex-grow">
        <router-link
          :to="{ name: 'adminHome' }"
          class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4"
        >
          Admin Interface
        </router-link>
        <router-link
          :to="{ name: 'educationOverview' }"
          class="
            block
            mt-4
            lg:inline-block
            lg:mt-0
            text-teal-200
            mr-4
            cursor-pointer
          "
        >
          Fortbildungen verwalten
        </router-link>
        <router-link
          :to="{ name: 'adminCertificates' }"
          class="
            block
            mt-4
            lg:inline-block
            lg:mt-0
            text-teal-200
            mr-4
            cursor-pointer
          "
        >
          Zertifikate erstellen
        </router-link>
      </div>
      <div>
        <router-link
          :to="{ name: 'logout' }"
          class="
            inline-block
            text-sm
            px-4
            py-2
            leading-none
            border
            rounded
            border-white
            hover:border-transparent
            hover:text-teal-500
            hover:bg-gray-50
            hover:text-black
            mt-4
            lg:mt-0
          "
          >Abmelden</router-link
        >
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { ref, defineComponent, reactive, onBeforeMount } from "vue";
import UserService from "../../services/UserService";

interface ScrollView {
  atTopOfPage: boolean;
}

export default defineComponent({
  name: "Header",
  props: {
    stickyEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const user = reactive(UserService.getCurrentUser());
    const showMobileMenu = ref(false);

    const menuIconToggle = () => {
      showMobileMenu.value = !showMobileMenu.value;
    };

    const view = ref<ScrollView>({ atTopOfPage: true });

    onBeforeMount(() => {
      if (props.stickyEnabled) window.addEventListener("scroll", handleScroll);
    });

    const handleScroll = () => {
      if (!props.stickyEnabled) return;

      if (window.pageYOffset > 0) {
        if (view.value.atTopOfPage) view.value.atTopOfPage = false;
      } else {
        if (!view.value.atTopOfPage) view.value.atTopOfPage = true;
      }
    };
    return { user, showMobileMenu, menuIconToggle, view };
  },
});
</script>
<style lang="postcss" scoped>
.menu-item.router-link-active {
  @apply bg-primary-dark;
  @apply text-white rounded-lg p-2.5;
}
</style>