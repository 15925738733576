<template>
  <button
    @click="submit"
    class="
      flex
      mt-5
      justify-center
      py-2.5
      px-4
      border border-transparent
      text-xs
      font-medium
      rounded-full
      text-white
      bg-primary
      shadow-md
      hover:shadow-xl hover:bg-primary-dark
      focus:outline-none
      transition
      duration-150
      ease-in-out
    "
  >
    <slot></slot>
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["submit"],
  setup(_, { emit }) {
    const submit = () => {
      emit("submit");
    };

    return { submit };
  },
});
</script>
