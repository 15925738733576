<template>
  <basic-layout>
    <template v-slot:header
      ><Header class="flex-initial" id="header"
    /></template>

    <div class="flex-grow flex flex-col items-center">
      <alert :show="showSubscriptionEndsAlert">
        <template v-slot:title>Warnung</template>
        Ihr Abonnement läuft am <b>{{ convertSubscriptionEndDate() }}</b> ab.
        Ihre Mitarbeiter werden deaktiviert und haben keinen Zugriff mehr auf
        die Fortbildungen.
        <template v-slot:action>
          <router-link
            :to="{ name: 'licensesPayment' }"
            class="text-sm font-light cursor-pointer hover:underline"
          >
            Mein Abonnement
          </router-link>
        </template>
      </alert>
      <alert :show="!showSubscriptionEndsAlert">
        <template v-slot:title>Info</template>
        Ihre Mitarbeiter können sich für die von Ihnen vorgesehene Lizenz über
        den jeweiligen Link oder den entsprechenden QR Code registrieren
      </alert>
      <div
        v-if="loading"
        class="flex-grow flex flex-col justify-center items-center space-y-10"
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-14 w-14 text-primary my-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div
        v-else
        class="z-20 flex flex-col space-y-5 md:grid md:grid-cols-3 p-10"
      >
        <div class="flex flex-col relative">
          <div class="absolute -left-10 -top-10 z-0">
            <img src="../assets/pattern.svg" class="z-0 h-52 bg-repeat" />
          </div>
          <div
            class="relative bg-white rounded-lg shadow-lg p-5 lg:mr-10 flex flex-col justify-between"
          >
            <div class="flex flex-col">
              <h2
                class="text-gray-900 text-lg lg:text-4xl font-bold leading-snug"
              >
                {{ company?.name }}
              </h2>
              <div class="mt-2 text-lg font-light">
                Willkommen zurück, {{ fullName }}.
                <hr class="mt-2 mb-2" />
                {{ company?.name }}<br />
                {{ companyAddress }}
              </div>
            </div>
            <transition
              enter-active-class="duration-700 ease-out"
              enter-from-class="transform opacity-0 scale-75"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="duration-200 ease-in"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-75"
              appear
            >
              <router-link
                to="/employees"
                v-if="unverifiedEmployeeCount > 0"
                class="bg-transparent text-center lg:px-4 mt-5"
                id="info"
              >
                <div
                  class="p-2 bg-yellow-300 shadow-md items-center text-gray-600 leading-none lg:rounded-full flex lg:inline-flex transition duration-500 ease-in-out transform hover:scale-110 hover:z-30 cursor-pointer"
                  role="alert"
                >
                  <span
                    class="flex rounded-full bg-yellow-100 text-black uppercase px-2 py-1 text-xs font-bold mr-3 tracking-wider"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                      /></svg
                  ></span>
                  <p class="font-light mr-2 text-left flex-auto flex flex-col">
                    <span
                      ><span class="font-bold">{{
                        unverifiedEmployeeCount
                      }}</span>
                      Mitarbeiter warten auf eine Bestätigung.</span
                    >
                  </p>
                </div>
              </router-link>
            </transition>
          </div>
          <div
            v-if="subscription != null"
            class="flex-none bg-white mt-5 rounded-lg shadow-lg p-5 lg:mr-10 flex flex-col"
          >
            <div class="flex justify-between items-center">
              <div class="flex space-x-2.5 items-center">
                <h2
                  class="text-gray-900 text-lg lg:text-2xl font-bold leading-snug"
                >
                  Abonnement
                </h2>
                <span
                  v-if="subscription.ends_at"
                  class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs shadow-md"
                >
                  Endet bald
                </span>
                <span
                  v-else
                  class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs shadow-md"
                >
                  Aktiv
                </span>
              </div>
              <router-link :to="{ name: 'licensesPayment' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 transform hover:scale-110 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </router-link>
            </div>
            <p v-if="subscription.ends_at" class="text-sm font-light">
              Ihr Abonnement endet am <b>{{ convertSubscriptionEndDate() }}</b
              >.<br />
              Sie können es jederzeit wieder aktivieren.
            </p>
            <p v-else>
              Sie haben ein aktives Abonnement.<br />
              <span v-if="excludedUserCount > 0"
                >Sie haben {{ excludedUserCount }} weitere Benutzer
                freigeschaltet, zusätzlich zu Ihrem Abonnement.</span
              >
            </p>
          </div>

          <div
            class="relative bg-white rounded-lg shadow-lg p-5 mt-5 lg:mr-10 flex flex-col justify-between"
          >
            <div class="flex flex-col">
              <h2
                class="text-gray-900 text-lg lg:text-2xl font-bold leading-snug"
              >
                Firmeninterne Funktionen
              </h2>
              <div class="mt-2 text-base font-light">
                Sie können Firmeninterne Fortbildungen / Informationen
                veröffentlichen und interne Mitarbeitergruppen erstellen.<br />
                <span class="text-xs font-light text-gray-400"
                  >Diese sind nur für Ihre Mitarbeiter</span
                >
              </div>
              <div class="flex flex-col xl:flex-row xl:space-x-5">
                <router-link :to="{ name: 'customTraining' }" class="mt-4">
                  <primary-button> Fortbildung erstellen </primary-button>
                </router-link>
                <router-link
                  :to="{ name: 'customTraining', query: { tab: '2' } }"
                  class="mt-4"
                >
                  <div class="relative">
                    <primary-button>
                      Mitarbeitergruppen verwalten
                    </primary-button>
                    <div
                      class="absolute rotate-12 transform top-0 right-0 -mt-7 -mr-4"
                    >
                      <div
                        class="bg-red-500 bg-opacity-70 backdrop-filter backdrop-blur-sm rounded-full flex items-center justify-center text-white py-2 px-3"
                      >
                        Neu
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="flex flex-col xl-flex-row xl:space-x-5">
                <router-link :to="{ name: 'customTraining', query: { tab: '3' } }" class="mt-4">
                  <primary-button>Fortbildungs-Pool</primary-button>
                </router-link>
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-10 lg:mr-10 space-y-5">
            <transition name="ease-out-overlay">
              <div
                v-if="showDashboardHelp"
                id="alert-additional-content-1"
                class="p-4 bg-yellow-200 shadow-lg rounded-lg"
                role="alert"
              >
                <div class="flex items-center">
                  <svg
                    class="mr-2 w-5 h-5 text-yellow-700"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <h3
                    class="text-lg font-medium text-yellow-700"
                  >
                    Dashboard Kurzanleitung
                  </h3>
                </div>
                <div>
                  <div
                    class="mt-2 mb-4 text-sm font-light text-yellow-700"
                  >
                    Sie finden hier eine Kurzanleitung zum Dashboard der 1-Minute.App<br />
                  </div>
                  <div class="flex">
                    <a
                      href="https://download.1-minute.app/Dashboard-Informationen-1-Minute-App.pdf"
                      type="button"
                      target="_blank"
                      class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center"
                    >
                      <svg
                        class="-ml-0.5 mr-2 h-4 w-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      Öffnen
                    </a>
                    <button
                      type="button"
                      class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center"
                      @click="showDashboardHelp = false"
                    >
                      Schließen
                    </button>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="ease-out-overlay">
              <div
                v-if="showFeedback"
                id="alert-additional-content-1"
                class="p-4 bg-indigo-200 shadow-lg dark:bg-blue-200 rounded-lg"
                role="alert"
              >
                <div class="flex items-center">
                  <svg
                    class="mr-2 w-5 h-5 text-indigo-700 dark:text-blue-800"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <h3
                    class="text-lg font-medium text-indigo-700 dark:text-blue-800"
                  >
                    Themenvorschläge?
                  </h3>
                </div>
                <div>
                  <div
                    class="mt-2 mb-4 text-sm font-light text-indigo-700 dark:text-blue-800"
                  >
                    Welche Themen bzw. Themenbereiche interessieren Sie
                    besonders?<br />
                    Welches spezielle Thema sollen wir aufnehmen?<br />
                    Schreiben Sie uns gerne Ihre Wünsche und Vorschläge.<br />
                  </div>
                  <div class="flex">
                    <a
                      href="https://bit.ly/3JX1p5y"
                      type="button"
                      target="_blank"
                      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-800 dark:hover:bg-blue-900"
                    >
                      <svg
                        class="-ml-0.5 mr-2 h-4 w-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      Feedback geben
                    </a>
                    <button
                      type="button"
                      class="text-blue-700 bg-transparent border border-blue-700 hover:bg-blue-800 hover:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:border-blue-800 dark:text-blue-800 dark:hover:text-white"
                      @click="showFeedback = false"
                    >
                      Schließen
                    </button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="col-span-2 md:ml-5 lg:ml-0">
          <div class="flex space-x-20 items-center mb-5">
            <h1
              v-if="
                company &&
                company.licenses &&
                company.licenses.filter((l) => l.enabled).length > 0
              "
              class="text-4xl font-extralight"
            >
              Aktive Lizenzen ({{
                company?.licenses.filter((l) => l.enabled).length
              }})
            </h1>
            <router-link :to="{ name: 'licensesPayment' }">
              <button class="text-sm font-medium bg-white p-2 shadow rounded">
                Abonnement / Lizenz Bereich Einstellungen
              </button>
            </router-link>
          </div>
          <div
            class="relative grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-4"
          >
            <div
              v-if="company?.licenses.length == 0 && subscription == null"
              class="bg-white col-span-4 rounded-lg shadow-md p-5 flex-col text-center flex space-y-10 justify-center"
            >
              <p class="text-2xl font-semibold">
                Sie haben noch keine Lizenz erworben.
              </p>

              <router-link :to="{ name: 'licensesPayment' }">
                <a
                  class="self-center bg-primary text-gray-100 p-4 w-1/3 text-sm rounded-full font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-primary-dark shadow-md"
                >
                  Alle Lizenzen & Preise
                </a>
              </router-link>
            </div>
            <div
              v-else-if="company?.licenses.length == 0 && subscription != null"
              class="bg-white col-span-4 rounded-lg shadow-md p-5 flex-col text-center flex space-y-10 justify-center"
            >
              <p>
                Sie haben bereits ein Lizenz Paket erworben, es aber noch nicht
                konfiguriert.
              </p>
              <router-link to="/licenses" class=" self-center">
                <primary-button>Konfigurieren</primary-button>
              </router-link>
              
            </div>
            <div
              v-for="license in company?.licenses.filter((l) => l.enabled)"
              v-else
              :key="license.id"
              class="bg-white rounded-lg shadow-md p-5 flex flex-col justify-center transition duration-500 ease-in-out transform hover:scale-110 hover:z-30"
            >
              <h3 class="text-md md:text-lg font-semibold text-center">
                {{ license.license_name }}
              </h3>
              <div class="text-center">
                <span class="text-xs md:text-sm font-light">
                  {{ license.user_count }}
                </span>
                <span class="text-xs md:text-sm"> Mitarbeiter</span>
              </div>
              <QrCode
                class="qrcode self-center my-5"
                :value="generateQrCodeUrl(license.key)"
                level="H"
              />
              <div
                class="url flex flex-cols-2 space-x-3 items-center justify-center p-2.5 bg-gray-200 shadow-md rounded-md break-all cursor-pointer"
                @click="copyUrlToClipboard(license.key)"
              >
                <p class="text-gray-400 text-xs">URL kopieren</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div class="flex flex-wrap gap-x-1.5 justify-center items-center">
                <router-link
                  :to="{
                    name: 'employeesByLicense',
                    params: { hashKey: license.key },
                  }"
                  class="flex-initial bg-primary hover:bg-primary-dark hover:shadow-lg text-white text-sm text-semibold px-4 py-2 rounded-full self-center mt-4"
                >
                  Mitarbeiter
                </router-link>
                <router-link
                  :to="{
                    name: 'statistics',
                    params: { hashKey: license.key },
                  }"
                  class="flex-initial bg-primary hover:bg-primary-dark hover:shadow-lg text-white text-sm text-semibold px-4 py-2 rounded-full self-center mt-4"
                >
                  Ergebnisse
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basic-layout>
</template>

<script lang="ts">
import { ref, computed, defineComponent, onMounted } from "vue";
import UserService, {
  ActiveSubscription,
  Company,
  User,
} from "../services/UserService";
import Header from "../components/layout/Header.vue";
import QrCode from "qrcode.vue";
import { createToast } from "mosha-vue-toastify";
import { useStore } from "vuex";
import moment from "moment";
import PrimaryButton from "@/components/layout/PrimaryButton.vue";

export default defineComponent({
  components: { Header, QrCode, PrimaryButton },
  setup() {
    const loading = ref<boolean>(true);
    const company = ref<Company>();
    const unverifiedEmployeeCount = ref<number>(0);
    const showFeedback = ref<boolean>(true);
    const showDashboardHelp = ref<boolean>(true);
    const store = useStore();

    const user = computed<User>(() => store.state.userModule.user);
    const showSubscriptionEndsAlert = computed(() => {
      return (
        user.value.active_subscription != null &&
        user.value.active_subscription.ends_at
      );
    });

    const convertSubscriptionEndDate = () => {
      return moment(subscription.value.ends_at).format("DD.MM.yyyy");
    };

    onMounted(async () => {
      loading.value = true;
      company.value = await UserService.fetchCompanyInfos(
        user.value.company_id
      );
      store.commit("licenseModule/setCompany", company.value);
      unverifiedEmployeeCount.value =
        await UserService.fetchUnverifiedEmployees();
      loading.value = false;
    });

    const fullName = computed(() => {
      return user.value.firstname + " " + user.value.lastname;
    });

    const companyAddress = computed(() => {
      if (company.value == null) return "";
      return (
        company.value.street +
        ", " +
        company.value.postcode +
        " " +
        company.value.city
      );
    });

    const generateQrCodeUrl = (keyHash: string) => {
      const url = window.location.origin + "/register/" + keyHash + "/employee";
      return url;
    };

    const copyUrlToClipboard = (url: string) => {
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = generateQrCodeUrl(url);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      createToast(
        {
          title: "Kopiert",
          description: "Die URL wurde kopiert.",
        },
        { type: "success", timeout: 2000 }
      );
    };

    const subscription = computed(
      () => store.state.paymentModule.activeSubscription as ActiveSubscription
    );

    const allSlotsForSubscription = computed(() => {
      return store.getters[
        "paymentModule/getAllSlotsForSubscription"
      ] as number;
    });

    const excludedUserCount = computed(() => {
      const verifiedUser = store.getters[
        "licenseModule/getVerifiedUserCount"
      ] as number;
      const included = allSlotsForSubscription.value;

      const extra = verifiedUser - included;
      return extra < 0 ? 0 : extra;
    });

    return {
      fullName,
      user,
      company,
      companyAddress,
      generateQrCodeUrl,
      loading,
      copyUrlToClipboard,
      unverifiedEmployeeCount,
      showFeedback,
      showSubscriptionEndsAlert,
      convertSubscriptionEndDate,
      subscription,
      excludedUserCount,
      showDashboardHelp,
    };
  },
});
</script>
<style scoped lang="postcss">
.animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ease-out-overlay-enter-active,
.ease-out-overlay-leave-active {
  @apply opacity-100 duration-300;
}

.ease-out-overlay-enter, .ease-out-overlay-leave-to /* .fade-leave-active below version 2.1.8 */ {
  @apply ease-in opacity-0 duration-200;
}

.ease-out-modal-enter-active,
.ease-out-modal-leave-active {
  @apply opacity-100 translate-y-0 sm:scale-100 duration-300;
}

.ease-out-modal-enter, .ease-out-modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  @apply ease-in opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 duration-200;
}
</style>