<template>
  <div class="">
    <div
      v-if="title"
      class="text-sm font-bold text-gray-700 tracking-wide mb-3 flex w-full"
      :class="{ 'justify-between': countChars }"
    >
      <p>{{ title }}</p>
      <p v-if="countChars">{{ totalChars }}/{{ maxChars }}</p>
    </div>
    <input
      v-if="inputType == 'input'"
      class="
        flex-1
        my-1
        appearance-none
        border border-transparent
        w-full
        py-2
        px-4
        bg-white
        disabled:opacity-50
        text-gray-700
        placeholder-gray-400
        shadow-md
        rounded-lg
        text-base
        focus:outline-none
        focus:ring-2
        focus:ring-primary
        focus:border-transparent
      "
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="onInput"
    />
    <textarea
      v-else
      class="
        flex-1
        appearance-none
        border border-transparent
        w-full
        py-2
        px-4
        bg-white
        disabled:opacity-50
        text-gray-700
        placeholder-gray-400
        shadow-md
        rounded-lg
        text-base
        focus:outline-none
        focus:ring-2
        focus:ring-primary
        focus:border-transparent
      "
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="onInput"
    >
    </textarea>
    <div v-if="error" class="text-xs text-red-800 px-2.5 py-2">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    title: String,
    type: String,
    placeholder: String,
    error: String,
    modelValue: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    countChars: {
      type: Boolean,
      default: false,
    },
    maxChars: {
      type: Number,
      default: 200,
    },
    inputType: {
      type: String,
      default: "input",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const totalChars = ref<number>();

    onMounted(() => {
      if (typeof props.modelValue === "string") {
        totalChars.value = props.modelValue.length;
      } else {
        totalChars.value = 1;
      }
    });

    const onInput = (e: Event) => {
      const target = e.target as HTMLInputElement;

      emit("update:modelValue", target.value);

      if (props.countChars && typeof props.modelValue === "string") {
        totalChars.value = target.value.length;
      }
    };

    return { onInput, totalChars };
  },
});
</script>
