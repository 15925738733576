<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `1Minute` }}</template>
  </metainfo>
  <div class="app">
    <template v-if="loading">
      <div class="flex justify-center items-center h-screen w-screen">
        <loading-spinner />
      </div>
    </template>
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import "mosha-vue-toastify/dist/style.css";
import { useMeta } from "vue-meta";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore()

    useMeta({
      title: '1Minute Fortbildungen',
      htmlAttrs: { lang: 'de', amp: false },
    })

    return {
      loading: computed(() => store.getters["userModule/isLoading"]),
    }
  }
});
</script>
