<template>
  <TransitionRoot :show="show">
    <!-- Sliding sidebar -->
    <TransitionChild
      enter="transition ease-in-out duration-300 transform"
      enter-from="-translate-x-full"
      enter-to="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-x-0"
      leave-to="-translate-x-full"
    >
      <div class="flex flex-col">
        <h1 class="text-lg font-medium my-2.5">Registrieren</h1>
        <div
          v-if="error"
          class="my-2.5 bg-red-200 flex flex-col p-2.5 rounded shadow"
        >
          <h1 class="text-base text-red-400 font-medium uppercase">Fehler</h1>
          <p class="text-xs md:text-sm" v-if="!message">
            Es ist ein Fehler ausgetreten. Bitte füllen Sie alle Felder aus.
          </p>
          <p class="text-xs md:text-sm" v-else>
            {{ message }}
          </p>
        </div>
        <Input v-model="email" label="Email" placeholder="Ihre Email Adresse" class="my-0.5" />
        <Input v-model="firstname" label="Vorname" placeholder="Ihr Vorname" class="my-0.5" />
        <Input v-model="lastname" label="Nachname" placeholder="Ihr Nachname" class="my-0.5" />
        <Input v-model="password" type="password" label="Passwort" placeholder="Ihr Passwort" class="my-0.5" />
        <Input v-model="passwordconfirm" type="password" label="Passwort wiederholen" placeholder="Passwort wiederholen" class="my-0.5" />

        <label class="text-xs mt-2.5"
          >Ihren Bereich für die Fortbildung auswählen:</label
        >
        <select
          v-if="!loadingLicenses"
          v-model="license_id"
          class="
            rounded
            mt-1
            shadow-md
            ring-0
            border-0
            focus:outline-none focus:ring-0
          "
        >
          <option
            v-for="license in allLicenses"
            :key="license.id"
            :value="license.id"
          >
            {{ license.name }}
          </option>
        </select>
        <loading-spinner v-else />
        <p class="text-xs font-light mt-5">
          *Nach erfolgreicher Bezahlung und Registrierung erhalten Sie Ihr
          Passwort sowie auch das Widerrufsrecht mit einer E-Mail.
        </p>
        <Checkbox v-model:checked="readRules">
          Ich habe die
          <router-link
            :to="{ name: 'agb' }"
            target="_blank"
            class="font-light cursor-pointer hover:underline"
          >
            AGB
          </router-link>
          gelesen und stimme zu.
        </Checkbox>
        <Button v-if="!loading" @submit="submit"
          >Registrieren und fortfahren</Button
        >
        <disabled-button
          buttonText="Bitte warten, Sie werden weitergeleitet"
          v-else
        />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { TransitionRoot, TransitionChild } from "@headlessui/vue";
import Input from "../forms/Input.vue";
import Button from "../layout/Button.vue";
import DisabledButton from "../DisabledButton.vue";
import ApiDataService, {
  License,
  RegisterPrivateAccountDTO,
} from "@/services/ApiDataService";
import Checkbox from "@/components/forms/Checkbox.vue";

export default defineComponent({
  components: {
    TransitionRoot,
    TransitionChild,
    Input,
    Button,
    DisabledButton,
    Checkbox,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const loading = ref(false);
    const loadingLicenses = ref(false);
    const allLicenses = ref<License[]>([]);

    const readRules = ref(false);

    const userRef = reactive<RegisterPrivateAccountDTO>({
      firstname: "",
      lastname: "",
      email: "",
      company_id: 4,
      license_id: -1,
      stripe_id: "",
      password: "",
      passwordconfirm: "",
    });

    watch(
      () => props.show,
      () => {
        loadingLicenses.value = true;
        ApiDataService.getPublicLicenses().then((licenses) => {
          if (!licenses.data) return;
          licenses.data.forEach((license) => {
            allLicenses.value.push(license);
          });
          licenses.data.unshift({
            id: -1,
            name: "Bitte wählen",
          });
          allLicenses.value = licenses.data;
          loadingLicenses.value = false;
        });
      }
    );

    watch(
      () => props.error,
      () => {
        if (props.error) {
          loading.value = false;
        }
      }
    );

    const submit = () => {
      loading.value = true;
      emit("submit", userRef);
    };

    return {
      ...toRefs(userRef),
      loading,
      loadingLicenses,
      allLicenses,
      submit,
      readRules,
    };
  },
});
</script>
