import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center items-center h-screen w-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `1Minute`), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_loading_spinner)
          ]))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ])
  ], 64))
}