import { IRootState } from "@/main";
import { IInvoice, IUpcomingInvoice } from "@/models/payment_models";
import ApiDataService from "@/services/ApiDataService";
import UserService, { ActiveSubscription } from "@/services/UserService";
import axios from "axios";
import moment from "moment";
import { ActionContext, Module } from "vuex";

export interface ISubscriptionPlan {
  id: number;
  name: string;
  nickname: string;
  type: string;
  stripe_id: string;
  amount?: number;
  licenses?: number;
  included?: number;
}

interface IPaymentState {
  loading: boolean;
  error?: string;
  plans: ISubscriptionPlan[];
  selectedProduct?: ISubscriptionPlan;
  generatedCheckoutLink?: string;
  activeSubscription?: ActiveSubscription;

  upcomingInvoice?: IUpcomingInvoice;
  invoices?: IInvoice[];

  loadingCancelSubscription: boolean;
}

export const paymentModule: Module<IPaymentState, IRootState> = {
  namespaced: true,
  state: {
    loading: false,
    error: undefined,
    plans: [],
    selectedProduct: undefined,
    generatedCheckoutLink: undefined,
    activeSubscription: undefined,
    loadingCancelSubscription: false,
  },
  getters: {
    getAllSlotsForSubscription: (state: IPaymentState): number => {
      const items = state.activeSubscription?.items;
      if (items) {
        if (items.length == 1) {
          const plan = state.plans.find(p => p.stripe_id == items[0].stripe_price);
          if (!plan) return 0;

          const seats = state.plans.find(p => p.nickname == plan?.nickname + "_users");

          return seats?.included ?? 0;
        }

        return items.map((item) => { return state.plans.find(p => p.stripe_id == item.stripe_price)?.included ?? 0; }).reduce((a, b) => a + b, 0);
      }
      return 0;
    },
    getMaxLicensesForSubscription: (state: IPaymentState): number => {
      if (state.activeSubscription == null) return 0;

      return state.activeSubscription.items.map((item) => {
        const plan = state.plans.find(p => p.stripe_id == item.stripe_price);
        return plan?.licenses ?? 0;
      }).reduce((a,b) => a + b, 0);
    },
    getIncludedUsersForSubscription: (state: IPaymentState) => (nickname: string) => {
      return state.plans.find(p => p.nickname == nickname + "_users");
    }
  },
  mutations: {
    triggerLoading: (state: IPaymentState) => (state.loading = !state.loading),
    setSelectedProduct: (state: IPaymentState, product: ISubscriptionPlan) => {
      if (state.loading) return;
      state.selectedProduct = product;
    },
    setGeneratedCheckoutLink: (state: IPaymentState, link: string) =>
      (state.generatedCheckoutLink = link),
    setActiveSubscription: (
      state: IPaymentState,
      subscription: ActiveSubscription
    ) => (state.activeSubscription = subscription),
    clearError: (state: IPaymentState) => state.error = undefined,
  },
  actions: {
    fetchSubscriptionPlans: async (context: ActionContext<IPaymentState, IRootState>) => {
      context.commit("triggerLoading");
      try {
        const response = await ApiDataService.fetchSubscriptionPlans(UserService.getAccessToken());
        if (response.status == 200) {
          context.state.plans = response.data;
        }
      } catch {

      }
      context.commit("triggerLoading");
    },
    createCheckoutLink: async (
      context: ActionContext<IPaymentState, IRootState>
    ) => {
      context.commit("triggerLoading");

      if (context.state.selectedProduct) {
        try {
          const response = await UserService.createBusinessCheckoutSession(
            context.state.selectedProduct?.stripe_id,
          );
          if (response.status == 200) {
            context.commit("setGeneratedCheckoutLink", response.data.url);
          }
        } catch {
          context.state.error = "Es ist ein unbekannter Fehler aufgetreten.";
        }
      }

      context.commit("triggerLoading");
    },
    updateSubscriptionPlan: async (
      context: ActionContext<IPaymentState, IRootState>
    ) => {
      context.commit("triggerLoading");
      if (context.state.selectedProduct) {
        try {
          const subscription = await UserService.updateSubscriptionPlan(
            context.state.selectedProduct?.stripe_id
          );
          if (subscription.status == 200 && subscription.data) {
            subscription.data.items.data.forEach((item) => {
              const activePlan = context.state.activeSubscription?.items.find(i => i.stripe_id == item.id);
              if (activePlan) {
                activePlan.stripe_price = item.price.id;
                activePlan.stripe_product = item.price.product;
                activePlan.quantity = item.quantity;
              }
            });
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (e.response?.data == 'Es fehlen Bezahlinformationen') {
              context.state.error = 'Bitte fügen Sie eine Bezahlmethode hinzu.';
            } else if (e.response?.data == 'Ein Upgrade ist erst nach der kostenlosen Testphase möglich.') {
              context.state.error = 'Ein Upgrade ist erst nach der kostenlosen Testphase möglich.';
            }
          } else {
            context.state.error = "Es ist ein unbekannter Fehler aufgetreten.";
          }
        }
      }
      context.commit("triggerLoading");
    },

    // fetch upcoming invoice
    fetchUpcomingInvoice: async (
      context: ActionContext<IPaymentState, IRootState>
    ) => {
      context.commit("triggerLoading");
      const token = UserService.getAccessToken();
      try {
        const response = await ApiDataService.getUpcomingInvoice(token);
        if (response.status == 200) {
          context.state.upcomingInvoice = response.data;
        }
      } catch {}
      try {
        const response = await ApiDataService.getAllInvoices(token);
        if (response.status == 200) {
          context.state.invoices = response.data;
        }
      } catch {}
      context.commit("triggerLoading");
    },

    // cancel subscription
    cancelSubscription: async (
      context: ActionContext<IPaymentState, IRootState>
    ) => {
      context.state.loadingCancelSubscription = true;
      const token = UserService.getAccessToken();
      try {
        const response = await ApiDataService.cancelSubscription(token);
        if (response.status == 200) {
          context.state.activeSubscription = response.data;
          const endsAt = moment(
            context.state.activeSubscription.ends_at
          ).format("DD.MM.yyyy");
          const alert = {
            title: "Abonnement wurde beendet",
            message: `Ihr Abonnement wurde beendet, sie können die Funktionen bis ${endsAt} weiterhin benutzen.`,
            type: "success",
          };
          context.dispatch("alertModule/createAlert", alert, { root: true });
        }
      } catch {}
      context.state.loadingCancelSubscription = false;
    },

    reactivateSubscription: async (context: ActionContext<IPaymentState, IRootState>) => {
      const token = UserService.getAccessToken();
      try {
        const response = await ApiDataService.reactiveSubscription(token);
        if (response.status == 200) {
          context.state.activeSubscription = response.data;
          const alert = {
            title: "Abonnement wurde re-aktiviert",
            message: `Ihr Abonnement wird fortgesetzt.`,
            type: "success",
          };
          context.dispatch("alertModule/createAlert", alert, { root: true });
        }
      } catch {}
    },

    createCustomerPortalUrl: async (context: ActionContext<IPaymentState, IRootState>) => {
      const token = UserService.getAccessToken();
      try {
        const response = await ApiDataService.createCustomerPortalUrl(token);
        return response.data;
      } catch {
        return null;
      }
    },
  },
};
