import { IRootState } from "@/main";
import { Quiz, QuizResultsResponse } from "@/services/AdminService";
import EmployeeService from "@/services/EmployeeService";
import ShopService from "@/services/ShopService";
import UserService, { User } from "@/services/UserService";
import {
  ICreateProductDTO,
  ICreateShopDTO,
  IEditShopDTO,
  Shop,
  ShopItem,
  ShopItemPurchase,
} from "@/types/shop";
import { ActionContext, Module } from "vuex";

interface IShopState {
  allUsers: {
    loading: boolean;
    data: User[];
  };
  userQuizzes: {
    loading: boolean;
    quizzes: QuizResultsResponse[];
    difference: number;
  };
  shop?: Shop;
  pendingPurchases: Array<ShopItemPurchase>;
  createShop: boolean;
  createProduct: boolean;
  editShop: boolean;
  addItemError: string;
}

export const shopModule: Module<IShopState, IRootState> = {
  namespaced: true,
  state: {
    allUsers: {
      loading: false,
      data: [],
    },
    userQuizzes: {
      loading: false,
      quizzes: [],
      difference: 0,
    },
    shop: undefined,
    pendingPurchases: [],
    createShop: false,
    createProduct: false,
    editShop: false,
    addItemError: "",
  },
  getters: {
    shop: (state: IShopState) => {
      return state.shop;
    },
    getUsernameById:
      (state: IShopState) =>
        (userId: number): string => {
          const user = state.allUsers.data.find((u) => u.id == userId);
          if (user) {
            return `${user.firstname} ${user.lastname}`;
          }
          return "";
        },
    getUser:
      (state: IShopState) =>
        (userId: number): User | undefined => {
          const user = state.allUsers.data.find((u) => u.id == userId);
          return user;
        },
  },
  mutations: {
    setShop: (state: IShopState, shop: Shop) => {
      state.shop = shop;
    },
    addProduct: (state: IShopState, product: ShopItem) => {
      if (state.shop) {
        if (state.shop.items) state.shop.items.push(product);
        else {
          state.shop.items = [];
          state.shop.items.push(product);
        }
      }
    },
  },
  actions: {
    fetchUsers: async (
      context: ActionContext<IShopState, IRootState>,
      company_id: number
    ) => {
      context.state.allUsers.loading = true;
      const response = await EmployeeService.fetchAllEmployeesWithoutPaging(
        company_id
      );
      if (response) {
        context.state.allUsers.data = response;
      }
      context.state.allUsers.loading = false;
    },
    fetchQuizzes: async (
      context: ActionContext<IShopState, IRootState>,
      payload: number[]
    ) => {
      const response = await EmployeeService.getQuizzesForUserResults(
        payload[0]
      );
      context.state.userQuizzes.difference = payload[1] - response.length;
      if (context.state.userQuizzes.difference < 0)
        context.state.userQuizzes.difference = 0;

      context.state.userQuizzes.quizzes = response;
    },
    fetchShop: async (context: ActionContext<IShopState, IRootState>) => {
      const company_id = UserService.getCurrentUser().company_id;
      const response = await ShopService.fetchShop(company_id);
      if (response.status == 200) context.state.shop = response.data;
    },
    createShop: async (
      context: ActionContext<IShopState, IRootState>,
      shopDTO: ICreateShopDTO
    ) => {
      shopDTO.company_id = UserService.getCurrentUser().company_id;

      context.state.createShop = true;
      const response = await ShopService.createShop(shopDTO);
      if (response.status == 200 && response.data) {
        context.commit("setShop", response.data);
      }
      context.state.createShop = false;
    },
    createShopItem: async (
      context: ActionContext<IShopState, IRootState>,
      itemDTO: ICreateProductDTO
    ) => {
      if (!context.state.shop) return;

      context.state.createProduct = true;
      itemDTO.shop_id = context.state.shop.id;

      try {
        const response = await ShopService.createProduct(itemDTO);
        if (response.status == 200 && response.data) {
          context.state.addItemError = "";
          context.commit("addProduct", response.data);
        } else {
          context.state.addItemError = "Es ist ein Fehler aufgetreten.";
        }
      } catch (_) {
        context.state.addItemError = "Es ist ein Fehler aufgetreten.";
      }

      context.state.createProduct = false;
    },
    editShop: async (
      context: ActionContext<IShopState, IRootState>,
      shopDTO: IEditShopDTO
    ) => {
      context.state.editShop = true;
      const response = await ShopService.editShop(shopDTO);
      if (response.status == 200 && response.data) {
        context.commit("setShop", response.data);
      }
      context.state.editShop = false;
    },
    deleteProduct: async (
      context: ActionContext<IShopState, IRootState>,
      id: number
    ) => {
      try {
        const response = await ShopService.deleteProduct(id);
        if (response.status == 200) {
          if (context.state.shop && context.state.shop.items) {
            context.state.shop.items = context.state.shop.items.filter(
              (item) => item.id != id
            );
          }
        }
      } catch (_) { }
    },
    fetchPendingPurchases: async (
      context: ActionContext<IShopState, IRootState>
    ) => {
      try {
        if (!context.state.shop) await context.dispatch("fetchShop");
        if (context.state.shop == null) return;
        if (context.state.allUsers.data.length == 0)
          await context.dispatch(
            "fetchUsers",
            UserService.getCurrentUser().company_id
          );
        const response = await ShopService.fetchPendingPurchases(
          context.state.shop!.id!
        );
        if (response.status == 200) {
          context.state.pendingPurchases = response.data;
        }
      } catch (_) { }
    },

    updatePurchaseStatus: async (
      context: ActionContext<IShopState, IRootState>,
      id: number
    ): Promise<boolean> => {
      const response = await ShopService.updatePurchaseStatus(id);
      if (response.status == 200) {
        return true;
      }
      return false;
    },

    updateProduct: async (context: ActionContext<IShopState, IRootState>, options: { id: number, item: ICreateProductDTO }) => {
      context.state.createProduct = true;

      const response = await ShopService.updateShopProduct(options.id, options.item);
      if (response.status == 200 && response.data) {
        if (context.state.shop && context.state.shop.items) {
          const index = context.state.shop.items.findIndex((item) => item.id == options.id);
          if (index != -1) {
            context.state.shop.items[index] = response.data;
          }
        }
      }

      context.state.createProduct = false;
    },
  },
};
