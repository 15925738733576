import UserService, { User } from "@/services/UserService";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import PrivateAccount from "../views/frontend/PrivateAccount.vue";
import { store } from "@/main";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "frontend",
    // component: import(/* webpackChunkName: "frontend" */ "../views/Login.vue"),
    redirect: { name: 'login' },
    meta: { requiresAuth: false },
  },
  {
    path: "/private",
    name: "private",
    component: PrivateAccount,
    meta: {
      requiresAuth: false,
      title: "1Minute Fortbildungen - Private Fortbildung",
    },
  },
  {
    path: "/payment/success",
    name: "payment-success",
    component: () => import("../views/payments/PaymentSuccessPage.vue"),
    meta: {
      requiresAuth: false,
      title: "1Minute Fortbildungen - Zahlung erfolgreich",
    },
  },
  {
    path: "/dashboard",
    name: "home",
    component: Home,
    meta: { requiresAuth: true, title: "1Minute - Dashboard" },
  },
  {
    path: "/admin",
    name: "adminHome",
    component: () =>
      import(
        /* webpackChunkName: "adminHome" */ "../views/admin/UploadEducationQuiz.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "1Minute - Administration",
    },
  },
  {
    path: "/admin/education-overview",
    name: "educationOverview",
    component: () =>
      import(
        /* webpackChunkName: "educationOverview" */ "../views/admin/EducationOverview.vue"
      ),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/admin/certificates",
    name: "adminCertificates",
    component: () => import("../views/admin/CreateCertificates.vue"),
    meta: { requiresAuth: false, authUserAllowed: true },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      requiresAuth: false,
      authUserAllowed: false,
      title: "1Minute - Anmelden",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: {
      requiresAuth: false,
      authUserAllowed: false,
    },
  },
  {
    path: "/password/forgot",
    name: "passwordForgot",
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ "../views/ForgotPassword.vue"
      ),
    meta: {
      requiresAuth: false,
      authUserAllowed: false,
      title: "1Minute - Passwort vergessen",
    },
  },
  {
    path: "/password/reset",
    name: "passwordReset",
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ "../views/ResetPassword.vue"
      ),
    meta: {
      requiresAuth: false,
      authUserAllowed: false,
      title: "1Minute - Passwort zurücksetzen",
    },
  },
  {
    path: "/licenses",
    name: "licensesPayment",
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ "../views/payments/license_payment.vue"
      ),
    meta: {
      requiresAuth: true,
      authUserAllowed: false,
      title: "1Minute - Abonnement verwalten",
    },
  },
  {
    path: "/register/:id/employee",
    name: "registerEmployee",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/RegisterEmployee.vue"
      ),
    meta: {
      requiresAuth: false,
      authUserAllowed: false,
      title: "1Minute - Registrieren",
    },
  },
  {
    path: "/register/:id/employee/success",
    name: "registerEmployeeSuccess",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/RegisterSuccessfully.vue"
      ),
    meta: { requiresAuth: false, authUserAllowed: false },
  },
  {
    path: "/private/create-account",
    name: "privateCreateAccount",
    component: () => import("../views/PrivateRegister.vue"),
    meta: { requiresAuth: false, authUserAllowed: false },
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/employees",
    name: "employees",
    component: () =>
      import(/* webpackChunkName: "employees" */ "../views/Employees.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/employees/:hashKey",
    name: "employeesByLicense",
    component: () =>
      import(
        /* webpackChunkName: "employeesByLicense" */ "../views/EmployeesByLicense.vue"
      ),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/statistics/:hashKey",
    name: "statistics",
    component: () => import("../views/Statistics.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/password",
    name: "settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/Settings.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/licenses",
    name: "licenseSettings",
    component: () => import("../views/settings/LicenseSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings/payment",
    name: "paymentSettings",
    component: () => import("../views/settings/Payment.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bonuses",
    name: "bonuses",
    component: () => import("../views/BonusesPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bonuses/shop",
    name: "shop",
    component: () => import("../views/BonusesShop.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bonuses/pending",
    name: "bonusesList",
    component: () => import("../views/BonusesList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/features/training",
    name: "customTraining",
    component: () => import("../views/features/tabs.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/internal-groups",
    name: "internalGroups",
    component: () => import("../views/InternalCompanyGroup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/PageNotFound.vue"),
    meta: { requiresAuth: false, authUserAllowed: true },
  },
  {
    path: "/policy/agb",
    name: "agb",
    component: () => import("../views/policy/agb.vue"),
    meta: { requiresAuth: false, authUserAllowed: true },
  },
  {
    path: "/policy/imprint",
    name: "imprint",
    component: () => import("../views/policy/imprint.vue"),
    meta: { requiresAuth: false, authUserAllowed: true },
  },
  {
    path: "/policy/privacy",
    name: "privacy",
    component: () => import("../views/policy/privacy.vue"),
    meta: { requiresAuth: false, authUserAllowed: true },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = UserService.isLoggedIn();

  store.commit('userModule/toggleLoading')

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (loggedIn) {
      let user = UserService.getCurrentUser();
      if (user == null) {
        user = (await store.dispatch("userModule/fetchCurrentUser")) as User;
      }

      store.commit("userModule/setUser", user);

      if (to.name == "home" && user.role?.name == "admin") {
        next({ name: "adminHome" });
      } else if (
        to.matched.some((record) => record.meta.requiresAdmin) &&
        user.role?.name != "admin"
      ) {
        next({ name: "home" });
      }
      next();
    } else {
      if (to.name) {
        next({ name: "login", query: { redirect: to.name.toString() } });
      } else {
        next({name: "login"})
      }
      
    }
  } else {
    if (loggedIn && to.matched.some((record) => !record.meta.authUserAllowed))
      next({ name: "home" });
    else next();
  }

  store.commit('userModule/toggleLoading')
});

export default router;
