import ApiDataService, {
  AdditionalUsersDTO,
  CheckoutSessionDTO,
  EmployeeIdentifier,
  LoginResponse,
  QuizPagination,
} from "./ApiDataService";
import jwt from "jsonwebtoken";
import { ChangePasswordDTO, ChangePasswordResponse } from "@/models/user_model";
import { AxiosResponse } from "axios";
import { store } from "@/main";
import { StripeSubscription } from "@/models/payment_models";
import { Answer, Question, Quiz } from "./AdminService";

// todo move to env file or envrionment variables
const jwtSecrect =
  "qysJCQBucX1eLuBXpc78pZz24JtzqbJZVgSM21rET2KY1J2KvyQNtKQkLODxAGxn";

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  company_id: number;
  street: string;
  postcode: string;
  city: string;
  recognition: string;
  created_at: string;
  is_verified: boolean;
  user_license: string;
  role?: Role;
  company_licenses: CompanyLicense[];
  user_quiz_results?: number[];
  points: UserPoint;
  active_subscription: ActiveSubscription;
}

export interface ActiveSubscription {
  created_at: string;
  id: number;
  ends_at?: string;
  stripe_id: string;
  stripe_price: string;
  stripe_status: string;
  updated_at: string;
  user_id: number;
  items: SubscriptionItem[];
}

export interface SubscriptionItem {
  id: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  stripe_id: string;
  stripe_price: string;
  stripe_product: string;
}

export interface Company {
  id: number;
  name: string;
  street: string;
  postcode: string;
  city: string;
  is_verified: boolean;
  licenses: CompanyLicense[];
}

export interface CompanyLicense {
  id: number;
  license_id: number;
  company_id: number;
  key: string;
  user_amount: number;
  user_count: number;
  license_name: string;
  type: string;
  label: string;
  can_change: boolean;
  enabled: boolean;
}

export interface UserPoint {
  created_at: string;
  id: number;
  points: number;
  redeemed_points: number;
  updated_at: string;
  user_id: number;
}

export interface Role {
  id: number;
  name: string;
}

class UserService {
  getCurrentUser(): User {
    return store.getters["userModule/getUser"] as User;
  }

  fetchCurrentUser(): Promise<AxiosResponse<User>> {
    return ApiDataService.getCurrentUser(this.getAccessToken());
  }

  async fetchCompanyInfos(id: number): Promise<Company> {
    const response = await ApiDataService.getCompanyById(
      id,
      this.getAccessToken()
    );
    return response.data;
  }

  async fetchUnverifiedEmployees(): Promise<number> {
    const response = await ApiDataService.getUnverifiedUsers(
      this.getAccessToken()
    );
    return response.data;
  }

  async fetchCompanyInfosByHash(hash: string): Promise<Company> {
    const response = await ApiDataService.getCompanyByHash(hash);
    return response.data;
  }

  storeUser(response: LoginResponse, token?: string) {
    if (token) localStorage.setItem("access_token", token);
    response.user.role = response.role;
    localStorage.setItem("user", JSON.stringify(response.user));
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem("access_token") ?? "";
    if (token === "") return false;

    jwt.verify(token, jwtSecrect, (err, decoded) => {
      if (err && err!.name != "NotBeforeError") {
        console.log("error", err);
        if (err) this.logout();
      }
    });

    return (
      localStorage.getItem("access_token") != null &&
      localStorage.getItem("user") != null
    );
  }

  getAccessToken(): string {
    return localStorage.getItem("access_token") || "";
  }

  logout(): Promise<void> {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    return Promise.resolve();
  }

  async updatePassword(
    dto: ChangePasswordDTO
  ): Promise<AxiosResponse<ChangePasswordResponse>> {
    return ApiDataService.updatePassword(dto, this.getAccessToken());
  }

  async updateEmployeeIdentifierForLicense(
    license: number,
    data: EmployeeIdentifier
  ): Promise<AxiosResponse<string>> {
    return ApiDataService.updateEmployeeIdentifierForLicense(
      license,
      data,
      this.getAccessToken()
    );
  }

  async createBusinessCheckoutSession(
    stripeId: string
  ): Promise<AxiosResponse<CheckoutSessionDTO>> {
    const token = this.getAccessToken();
    return ApiDataService.getCheckoutLinkBusinessUser(stripeId, token);
  }

  async updateSubscriptionPlan(
    stripeId: string
  ): Promise<AxiosResponse<StripeSubscription>> {
    const token = this.getAccessToken();
    return ApiDataService.updateSubscriptionPlan(stripeId, token);
  }

  async addAdditionalUsersToSubscription(
    additionalUsersDTO: AdditionalUsersDTO
  ): Promise<AxiosResponse<string>> {
    const token = this.getAccessToken();
    return ApiDataService.addAdditionalUsersToSubscription(
      additionalUsersDTO,
      token
    );
  }

  async getVerifiedUserCount(): Promise<AxiosResponse<number>> {
    const token = this.getAccessToken();
    return ApiDataService.getVerifiedUsersCount(token);
  }

  async toggleCompanyLicenseStatus(id: number): Promise<AxiosResponse<string>> {
    const token = this.getAccessToken();
    return ApiDataService.toggleCompanyLicenseEnabled(token, id);
  }

  async fetchUserSubscription(): Promise<AxiosResponse<ActiveSubscription>> {
    const token = this.getAccessToken();
    return ApiDataService.fetchUserSubscription(token);
  }

  async reactiveSubscription(): Promise<AxiosResponse<ActiveSubscription>> {
    const token = this.getAccessToken();
    return ApiDataService.reactiveSubscription(token);
  }

  async fetchCompanyQuizzes(page: number): Promise<AxiosResponse<QuizPagination>> {
    return ApiDataService.getCompanyQuizzes(page)
  }

  async deleteCompanyQuiz(id: number): Promise<AxiosResponse<string>> {
    return ApiDataService.deleteQuizForCompany(id);
  }

  async fetchQuizQuestions(id: number): Promise<AxiosResponse<Question[]>> {
    return ApiDataService.fetchQuizQuestions(id);
  }

  async editQuiz(quiz: Quiz): Promise<AxiosResponse<string>> {
    return ApiDataService.editQuiz(quiz);
  }

  async downloadQuizPdf(id: number): Promise<AxiosResponse<any>> {
    return ApiDataService.downloadQuizPrint(id);
  }
}

export default new UserService();
