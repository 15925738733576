export interface IProduct {
  name: string;
  description?: string;
  stripePriceId: string;
  price: string;
  savedAnnually?: string;
  maxLicenses?: number;
  maxUsers?: number;
}

export const privateProducts = {
  monthly: [
    <IProduct>{
      name: "Einzellizenz",
      stripePriceId: isDevelopment()
        ? "price_1LG5DxEmSBfnAmjFs8uhXljz"
        : "price_1NtyBPEmSBfnAmjFlcZdpeqJ",
      description: "Hier könnte eine Beschreibung stehen....",
      price: "3,48€",
    },
  ],
  annually: [
    <IProduct>{
      name: "Einzellizenz",
      stripePriceId: isDevelopment()
        ? "price_1KeP34EmSBfnAmjFrg4WtZF0"
        : "price_1NtyBgEmSBfnAmjF9hz1F8Wy",
      price: "39€",
    },
  ],
};

function isDevelopment() {
  if (process.env.NODE_ENV === "production") {
    return false;
  }
  return true;
}
