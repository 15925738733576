import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Loading from "@/components/Loading.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PrimaryButton from "@/components/layout/PrimaryButton.vue";

import "@/assets/styles/tailwind.css";

import VueApexCharts from "vue3-apexcharts";
import { createStore } from "vuex";
import { shopModule } from "./stores/shop_module";
import { userModule } from "./stores/user_module";
import { paymentModule } from "./stores/payment_module";
import { licenseModule } from "./stores/license_module";
import { alertModule } from "./stores/alert_module";
import { adminModule } from "./stores/admin_module";
import { companyModule } from "./stores/company_module";

import BasicLayout from "@/components/layout/BasicLayout.vue";
import Alert from "@/components/Alert.vue";

import { createMetaManager } from 'vue-meta'
import { createPinia } from "pinia";

export interface IRootState {
  root: boolean;
}

export const store = createStore<IRootState>({
  state() {
    return {
      root: true,
    };
  },
});

store.registerModule("shopModule", shopModule);
store.registerModule("userModule", userModule);
store.registerModule("paymentModule", paymentModule);
store.registerModule("licenseModule", licenseModule);
store.registerModule("alertModule", alertModule);
store.registerModule("adminModule", adminModule);
store.registerModule("companyModule", companyModule);

const pinia = createPinia();

createApp(App)
  .use(router)
  .use(VueApexCharts)
  .use(store)
  .use(pinia)
  .use(createMetaManager())
  .component("loading", Loading)
  .component("loading-spinner", LoadingSpinner)
  .component("primary-button", PrimaryButton)
  .component("basic-layout", BasicLayout)
  .component("alert", Alert)
  .mount("#app");
