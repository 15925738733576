<template>
  <button
    @click="emit('buttonClick')"
    disabled
    type="button"
    class="
      group
      relative
      w-full
      flex
      justify-center
      py-4
      px-6
      border border-transparent
      text-sm
      leading-5
      font-medium
      rounded-md
      text-white
      bg-primary
      focus:outline-none
      transition
      duration-150
      ease-in-out
      disabled:opacity-50 cursor-not-allowed
    "
  >
    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
      <svg
        class="animate-spin -ml-1 mr-3 h-8 w-8 text-white my-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DisabledButton",
  emits: ["buttonClick"],
  props: {
    buttonText: {
      type: String,
      default: "Laden...Bitte warten",
    },
  },
});
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>