import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["type", "placeholder", "value", "disabled"]
const _hoisted_4 = ["placeholder", "value", "disabled"]
const _hoisted_5 = {
  key: 3,
  class: "text-xs text-red-800 px-2.5 py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["text-sm font-bold text-gray-700 tracking-wide mb-3 flex w-full", { 'justify-between': _ctx.countChars }])
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
          (_ctx.countChars)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.totalChars) + "/" + _toDisplayString(_ctx.maxChars), 1))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.inputType == 'input')
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          class: "flex-1 my-1 appearance-none border border-transparent w-full py-2 px-4 bg-white disabled:opacity-50 text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }, null, 40, _hoisted_3))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          class: "flex-1 appearance-none border border-transparent w-full py-2 px-4 bg-white disabled:opacity-50 text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
          placeholder: _ctx.placeholder,
          value: _ctx.modelValue,
          disabled: _ctx.disabled,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }, "\n    ", 40, _hoisted_4)),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}