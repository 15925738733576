import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
    class: "flex mt-5 justify-center py-2.5 px-4 border border-transparent text-xs font-medium rounded-full text-white bg-primary shadow-md hover:shadow-xl hover:bg-primary-dark focus:outline-none transition duration-150 ease-in-out"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}