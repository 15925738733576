<template>
  <div class="flex mt-8">
    <label class="flex items-center">
      <input
        type="checkbox"
        class="form-checkbox text-primary"
        :checked="checked"
        @input="(event) => $emit('update:checked', event.target.checked)"
      />
      <span class="ml-2"><slot></slot></span>
    </label>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "Checkbox",
  props: {
    checked: Boolean,
  },
});
</script>
