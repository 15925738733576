<template>
  <div
    class="flex flex-col min-h-screen"
    :class="background"
    id="bodycontainer"
  >
    <slot name="header" />
    <slot name="alert" />
    <slot />
    <div
      class="
        flex-none flex flex-col
        space-x-10
        px-10
        py-10
        w-full
        bg-white
        items-end
        justify-end
      "
    >
      <div class="flex space-x-5">
        <div class="flex flex-col">
          <h3 class="font-semibold">1Minute &copy; 2021</h3>
          <router-link
            :to="{ name: 'imprint' }"
            target="_blank"
            class="text-sm font-light cursor-pointer hover:underline"
          >
            Impressum
          </router-link>
          <router-link
            :to="{ name: 'privacy' }"
            target="_blank"
            class="text-sm font-light cursor-pointer hover:underline"
          >
            Datenschutz
          </router-link>
          <router-link
            :to="{ name: 'agb' }"
            target="_blank"
            class="text-sm font-light cursor-pointer hover:underline"
          >
            AGB
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import FooterHand from "@/assets/rockstar_hands.svg";
export default defineComponent({
  props: {
    background: {
      type: String,
      default: "background",
    },
  },
  setup() {
    return { FooterHand };
  },
});
</script>
