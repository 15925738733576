import { IRootState } from "@/main";
import { Quiz } from "@/services/AdminService";
import UserService from "@/services/UserService";
import { ActionContext, Module } from "vuex";
import http from "@/modules/http-common";
import { createToast } from "mosha-vue-toastify";

interface IAdminState {
    loadingUpload: boolean;
    uploadSuccess: boolean;
}

export type SourcesMap = Record<string, string>;

interface ISource {
    fileName: string;
    source: string;
}

export const adminModule: Module<IAdminState, IRootState> = {
    namespaced: true,
    state: {
        loadingUpload: false,
        uploadSuccess: false,
    },
    mutations: {
        setLoadingUpload(state: IAdminState, loading: boolean) {
            state.loadingUpload = loading;
        },
    },
    actions: {
        // upload data
        async uploadData(context: ActionContext<IAdminState, IRootState>, options: { quiz: Quiz, files: File[], sources: SourcesMap }) {
            context.commit("setLoadingUpload", true);
            await context.dispatch("uploadQuizData", options);
            context.commit("setLoadingUpload", false);
        },

        // upload quiz data
        async uploadQuizData(context: ActionContext<IAdminState, IRootState>, options: { quiz: Quiz, files: File[], sources: SourcesMap }) {
            const token = UserService.getAccessToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            
            let createdQuizId = 0;

            try {
            const res = await http.post<Quiz>("admin/quiz/create", options.quiz, config);
            if (res.status == 200) {
                createdQuizId = res.data.id;
                await context.dispatch("uploadQuizImages", { id: res.data.id, quiz: options.quiz, files: options.files, sources: options.sources });
                context.state.uploadSuccess = true;
                    createToast(
                        {
                          title: "Erstellt",
                          description: "Die Fortbildung wurde erstellt.",
                        },
                        { type: "success", showIcon: true }
                      );
            }
            } catch (e) {
                context.state.uploadSuccess = false;
                context.commit("setLoadingUpload", false);
                    createToast(
                        {
                          title: "Fehler",
                          description: "Ein Fehler ist aufgetreten. " + e == undefined ? "" : e,
                        },
                        { type: "warning", showIcon: true }
                      );
                    await http.delete("admin/quiz/" + createdQuizId, config);
            }
        },
        // upload quiz images with quiz_id
        async uploadQuizImages(context: ActionContext<IAdminState, IRootState>, options: { id: number, quiz: Quiz, files: File[], sources: SourcesMap }){
            const token = UserService.getAccessToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "multipart/form-data",
                },
            };
            const formData = new FormData();
            formData.append("quiz_id", options.id.toString());
            options.files.forEach((file: File) => {
                formData.append("files[]", file);
            });

            const sources = Array<ISource>();

            for (const key in options.sources) {
                if (options.sources.hasOwnProperty(key)) {
                    const element = options.sources[key];
                    sources.push({
                        fileName: key,
                        source: element,
                    });
                }
            }

            formData.append("sources", JSON.stringify(sources));

            const notification = {
                "notification_enabled": options.quiz.notification_enabled,
                "notification_title": options.quiz.notification_title,
                "notification_text": options.quiz.notification_text,
            }
            formData.append("notification", JSON.stringify(notification));

            const res = await http.post<boolean>("admin/upload/" + options.id, formData, config);
        },
    },
};