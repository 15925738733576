import { IRootState, store } from "@/main";
import ApiDataService, {
  CreateLicenseDTO,
  License,
} from "@/services/ApiDataService";
import UserService, { Company, CompanyLicense } from "@/services/UserService";
import { ActionContext, Module } from "vuex";

interface ILicenseState {
  loading: boolean;
  dialogLoading: boolean;
  licenses: License[];
  company?: Company;
  availableUserSlots: number;
  showAddLicenseDialog: boolean;
  verifiedUserCount: number;
  showEditLicenseDialog: boolean;
  editCompanyLicense?: CompanyLicense;
}

export const licenseModule: Module<ILicenseState, IRootState> = {
  namespaced: true,
  state: {
    loading: false,
    dialogLoading: false,
    availableUserSlots: 0,
    verifiedUserCount: 0,
    showAddLicenseDialog: false,
    showEditLicenseDialog: false,
    editCompanyLicense: undefined,
    licenses: [],
    company: undefined,
  },
  mutations: {
    triggerLoading: (state: ILicenseState) => (state.loading = !state.loading),
    triggerDialogLoading: (state: ILicenseState) =>
      (state.dialogLoading = !state.dialogLoading),
    setAvailableUserSlots: (state: ILicenseState, slots: number) =>
      (state.availableUserSlots = slots),
    setLicenses: (state: ILicenseState, licenses: License[]) =>
      (state.licenses = licenses),
    setCompany: (state: ILicenseState, company: Company) =>
      (state.company = company),
    setCompanyLicense: (state: ILicenseState, license: CompanyLicense) =>
      (state.editCompanyLicense = license),
  },
  getters: {
    getLicenseNameById: (state: ILicenseState) => (id: number) => {
      return state.licenses.find((license) => license.id == id)?.name;
    },
    getAvailableLicenses: (state: ILicenseState) => {
      if (state.company == null) return state.licenses;
      return state.licenses.filter(
        (l) => !state.company!.licenses.some((c) => c.license_id == l.id)
      );
    },
    getUsedLicenseCount: (state: ILicenseState) => {
      return state.company == null
        ? 0
        : state.company.licenses.filter((l) => l.enabled).length;
    },
    getVerifiedUserCount: (state: ILicenseState) => state.verifiedUserCount,
  },
  actions: {
    async init(context: ActionContext<ILicenseState, IRootState>) {
      context.commit("triggerLoading");
      const user = context.rootGetters["userModule/getUser"];
      const response = await UserService.fetchCompanyInfos(user.company_id);
      context.state.verifiedUserCount = (
        await UserService.getVerifiedUserCount()
      ).data;
      context.commit("setCompany", response);
      context.dispatch("fetchLicenses");
      context.commit("triggerLoading");
    },
    async fetchLicenses(context: ActionContext<ILicenseState, IRootState>) {
      context.commit("triggerDialogLoading");
      const response = await ApiDataService.getPublicLicenses();
      if (response.status == 200) {
        context.commit("setLicenses", response.data);
      }
      context.commit("triggerDialogLoading");
    },
    // create license in database
    async createLicense(
      context: ActionContext<ILicenseState, IRootState>,
      license: CreateLicenseDTO
    ): Promise<boolean> {
      context.commit("triggerDialogLoading");
      let status = false;
      try {
        license.company_id = context.state.company?.id ?? -1;
        const token = UserService.getAccessToken();
        const updatedCompany = await ApiDataService.createLicense(
          license,
          token
        );

        context.commit("setCompany", updatedCompany.data);

        context.state.availableUserSlots -= license.user_count;

        const alert = {
          title: "Bereich wurde angelegt",
          message: "Der Bereich wurde erfolgreich angelegt.",
          type: "success",
        };
        context.dispatch("alertModule/createAlert", alert, { root: true });
        status = true;
      } catch {
        status = false;
      }
      context.commit("triggerDialogLoading");
      return status;
    },
    async updateCompanyLicense(
      context: ActionContext<ILicenseState, IRootState>,
      companyLicense: CompanyLicense
    ) {
      context.commit("triggerDialogLoading");
      const token = UserService.getAccessToken();
      const updatedCompanyLicense = await ApiDataService.updateCompanyLicense(
        companyLicense,
        token
      );
      if (context.state.company) {
        context.state.company.licenses = context.state.company.licenses.map(
          (l) =>
            l.id !== updatedCompanyLicense.data.id
              ? l
              : updatedCompanyLicense.data
        );
      }
      context.commit("triggerDialogLoading");
    },
    async toggleCompanyLicenseStatus(
      context: ActionContext<ILicenseState, IRootState>,
      id: number
    ) {
      context.commit("triggerDialogLoading");
      const status = await UserService.toggleCompanyLicenseStatus(id);
      
      if (status.status == 200) {
        context.commit("triggerDialogLoading");
        await context.dispatch("init");
      } else {
        context.commit("triggerDialogLoading");
      }
    },
  },
};
