import http from "@/modules/http-common";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Answer, Question, Quiz, QuizResultsResponse } from "./AdminService";
import UserService, {
  ActiveSubscription,
  Company,
  CompanyLicense,
  Role,
  SubscriptionItem,
  User,
} from "./UserService";
import { ChangePasswordDTO, ChangePasswordResponse } from "@/models/user_model";
import { IInvoice, IUpcomingInvoice, StripeSubscription } from "@/models/payment_models";
import { ISubscriptionPlan } from "@/stores/payment_module";

interface LoginPayload {
  email: string;
  password: string;
  logintype: string;
}

export interface RegisterPayload {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  password_confirmation: string;
  company: string;
  street: string;
  postcode: string;
  city: string;
  accepted_terms: boolean;
  phone: string;
}

export interface RegisterEmployeePayload {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  password_confirmation: string;
  recognition: string;
  company: number;
  license_id: number;
}

class ApiDataService {
  public login(payload: LoginPayload): Promise<AxiosResponse<LoginResponse>> {
    const data: string = JSON.stringify(payload);
    return http.post<LoginResponse>("/login", data);
  }

  public register(
    payload: RegisterPayload
  ): Promise<AxiosResponse<RegisterResponse>> {
    const data: string = JSON.stringify(payload);
    const response = http.post<RegisterResponse>("/company/register", data);
    return response;
  }

  public registerTest(
    payload: RegisterEmployeePayload
  ): Promise<AxiosResponse<RegisterResponse>> {
    const data: string = JSON.stringify(payload);
    const response = http.post<RegisterResponse>("/employee/register", data);
    return response;
  }

  public getCompanyById(
    id: number,
    token: string
  ): Promise<AxiosResponse<Company>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<Company>("/company/" + id, config);
  }

  public getCompanyByHash(hash: string): Promise<AxiosResponse<Company>> {
    const res = http.get<Company>("/company/hash/" + hash);
    return res;
  }

  public getUnverifiedUsers(token: string): Promise<AxiosResponse<number>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = http.get<number>("/company/employees/unverified", config);
    return res;
  }

  public getLatestUnverifiedUsers(
    company: number,
    token: string
  ): Promise<AxiosResponse<User[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<User[]>(
      "/company/" + company + "/latest/unverified",
      config
    );
  }
  public getAllEmployeesWithoutPaging(
    company: number,
    token: string
  ): Promise<AxiosResponse<User[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<User[]>(
      "/company/" + company + "/employees/nopage",
      config
    );
  }
  public getAllEmployees(
    company: number,
    token: string,
    page: number,
    sort: number
  ): Promise<AxiosResponse<UserPagination>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<UserPagination>(
      "/company/" + company + "/employees?page=" + page + "&sort=" + sort,
      config
    );
  }

  public getAllEmployeesByLicense(
    company: number,
    hashKey: string,
    token: string,
    page: number,
    sort: number
  ): Promise<AxiosResponse<UserPagination>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const res = http.get<UserPagination>(
      "company/" +
        company +
        "/employees/" +
        hashKey +
        "?page=" +
        page +
        "&sort=" +
        sort,
      config
    );
    return res;
  }

  public verifyUser(
    userId: number,
    token: string
  ): Promise<AxiosResponse<boolean>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.post<boolean>(
      "/company/user/verify",
      { user_id: userId },
      config
    );
  }

  public getCurrentUser(token: string): Promise<AxiosResponse<User>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get("/user", config);
  }

  public deleteUser(
    userId: number,
    token: string
  ): Promise<AxiosResponse<boolean>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.post<boolean>(
      "/company/user/delete",
      { user_id: userId },
      config
    );
  }

  public searchUser(
    keyword: string,
    token: string
  ): Promise<AxiosResponse<User[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.post<User[]>(
      "company/user/search",
      { keyword: keyword },
      config
    );
  }

  public getLicenseByHash(
    keyHash: string,
    token: string
  ): Promise<AxiosResponse<License>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<License>("license/" + keyHash, config);
  }

  public getAllLicenses(token: string): Promise<AxiosResponse<License[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<License[]>("licenses", config);
  }

  updatePassword(
    dto: ChangePasswordDTO,
    token: string
  ): Promise<AxiosResponse<ChangePasswordResponse>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.post<ChangePasswordResponse>(
      "/user/settings/password",
      dto,
      config
    );
  }

  updateEmployeeIdentifierForLicense(
    license: number,
    data: EmployeeIdentifier,
    token: string
  ): Promise<AxiosResponse<string>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.post<string>(
      `user/settings/employee/identifier/${license}`,
      data,
      config
    );
  }

  getQuizzesForUserResults(
    userId: number,
    token: string
  ): Promise<AxiosResponse<QuizResultsResponse[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return http.get<QuizResultsResponse[]>(`user/quizresults/${userId}`, config);
  }

  async sendForgotPasswordMail(email: string): Promise<string> {
    try {
      return (await http.post<string>(`password/forgot`, { email: email }))
        .data;
    } catch (error) {
      if (error) {
        const axiosError = error as AxiosError<string>;
        return axiosError.response?.data ?? "";
      }
      return "";
    }
  }

  sendResetPassword(data: ResetPasswordDTO): Promise<AxiosResponse<string>> {
    return http.post<string>(`password/reset`, data);
  }

  async getCheckoutLinkPrivateUser(
    data: RegisterPrivateAccountDTO
  ): Promise<AxiosResponse<CheckoutSessionDTO>> {
    return http.post<CheckoutSessionDTO>(`private/register`, data);
  }

  async getPublicLicenses(): Promise<AxiosResponse<License[]>> {
    return http.get<License[]>(`publiclicenses`);
  }

  async getCheckoutLinkBusinessUser(
    stripeId: string,
    token: string
  ): Promise<AxiosResponse<CheckoutSessionDTO>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<CheckoutSessionDTO>(
      `company/subscription/create`,
      { price_id: stripeId },
      config
    );
  }

  async updateSubscriptionPlan(
    stripeId: string,
    token: string
  ): Promise<AxiosResponse<StripeSubscription>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<StripeSubscription>(
      `company/subscription/create`,
      { price_id: stripeId },
      config
    );
  }

  async fetchUserSubscription(token: string): Promise<AxiosResponse<ActiveSubscription>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<ActiveSubscription>(`company/subscription/active`, config);
  }

  async addAdditionalUsersToSubscription(
    additionalUsersDTO: AdditionalUsersDTO,
    token: string
  ): Promise<AxiosResponse<string>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<string>(
      `company/subscription/extend`,
      additionalUsersDTO,
      config
    );
  }

  // create license
  async createLicense(
    createLicenseDTO: CreateLicenseDTO,
    token: string
  ): Promise<AxiosResponse<Company>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<Company>(`license`, createLicenseDTO, config);
  }

  async updateCompanyLicense(
    license: CompanyLicense,
    token: string
  ): Promise<AxiosResponse<CompanyLicense>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      id: license.id,
      label: license.label,
      enabled: license.enabled,
    };
    return http.post<CompanyLicense>(`license/update`, data, config);
  }

  async getVerifiedUsersCount(token: string): Promise<AxiosResponse<number>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<number>(`company/employees/verified`, config);
  }

  async toggleCompanyLicenseEnabled(
    token: string,
    id: number
  ): Promise<AxiosResponse<string>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<string>(`license/status`, { license_id: id }, config);
  }

  async getUpcomingInvoice(
    token: string
  ): Promise<AxiosResponse<IUpcomingInvoice>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<IUpcomingInvoice>(`subscription/invoice/upcoming`, config);
  }

  async getAllInvoices(token: string): Promise<AxiosResponse<IInvoice[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<IInvoice[]>(`subscription/invoices`, config);
  }

  async cancelSubscription(
    token: string
  ): Promise<AxiosResponse<ActiveSubscription>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<ActiveSubscription>(
      `company/subscription/cancel`,
      {},
      config
    );
  }

  async fetchSubscriptionPlans(token: string): Promise<AxiosResponse<ISubscriptionPlan[]>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<ISubscriptionPlan[]>(`company/subscription/plans`, config);
  }

  async createCustomerPortalUrl(token: string): Promise<AxiosResponse<string>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.get<string>(`company/subscription/portal`, config);
  }

  async reactiveSubscription(token: string): Promise<AxiosResponse<ActiveSubscription>> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return http.post<ActiveSubscription>(`subscription/reactivate`, {}, config);
  }

  async getCompanyQuizzes(page: number): Promise<AxiosResponse<QuizPagination>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<QuizPagination>(
      `company/custom/quizzes?page=${page}`,
      config
    );
    return res;
  }

  async deleteQuizForCompany(id: number): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.delete<string>(
      `company/custom/quiz/${id}`,
      config
    );
    return res;
  }

  async fetchQuizQuestions(id: number): Promise<AxiosResponse<Question[]>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.get<Question[]>(
      `company/custom/quiz/${id}/questions`,
      config
    );
    return res; 
  }

  async editQuiz(quiz: Quiz): Promise<AxiosResponse<string>> {
    const token = UserService.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = http.put<string>(
      `company/custom/quiz/${quiz.quiz_id}`,
      quiz,
      config,
    );
    return res; 
  }

  async downloadQuizPrint(id: number): Promise<AxiosResponse<any>> {
    const token = UserService.getAccessToken();
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer',
    };

    const res = http.get(
      `company/quiz/print/${id}`,
      config,
    );
    return res; 
  }

  async getRefreshSession(data: RefreshSubscriptionDTO): Promise<[AxiosResponse<CheckoutSessionDTO> | null, string | null]> {
    try {
      const res = await http.post(`private/renew-subscription`, JSON.stringify(data))
      return [res, null]
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        return [null, error.response?.data]
      } else {
        return [null, error?.data ?? 'Unbekannter Fehler']
      }
    }
  }
}

export interface LoginResponse {
  user: User;
  role: Role;
  access_token: string;
  expires_in: number;
  token_type: string;
}

export interface RegisterResponse {
  access_token: string;
}

export interface UserPagination {
  data: User[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
}

export interface QuizPagination {
  data: Quiz[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
}

export interface QuizPoolPagination {
  data: QuizResultsResponse[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
}

export interface QuizPoolResponse {
  quiz_id: number;
  quiz: Quiz;
}

export interface License {
  id: number;
  name: string;
}

export interface QuizResponse {
  id: number;
  title: string;
  created_at: string;
}

export interface EmployeeIdentifier {
  type: string;
  label: string;
  readonly: boolean;
}

export interface ResetPasswordDTO {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface CheckoutCustomerDetails {
  email: string;
  phone: string;
  tax_exempt: boolean;
  tax_ids: string[];
}

export interface CheckoutSessionDTO {
  url: string;
  client_reference_id: string;
  success_url: string;
  cancel_url: string;
}

export interface AdditionalUsersDTO {
  price_id: string;
  quantity: number;
}

export interface CreateLicenseDTO {
  license_id: number;
  user_count: number;
  company_id: number;
}

export interface RegisterPrivateAccountDTO {
  firstname: string;
  lastname: string;
  email: string;
  license_id: number;
  company_id: number;
  stripe_id: string;
  password: string;
  passwordconfirm: string;
}

export interface RefreshSubscriptionDTO {
  email: string;
  password: string;
  stripe_id: string;
}

export default new ApiDataService();
