import { IRootState } from "@/main";
import AdminService, { Answer, Question, Quiz } from "@/services/AdminService";
import { License, QuizPagination } from "@/services/ApiDataService";
import UserService from "@/services/UserService";
import { ActionContext, Module } from "vuex";

interface ICompanyState {
    loadedQuizzes?: QuizPagination;
    loadingQuizzes: boolean;
    licenses: License[],

    editQuiz?: Quiz,
    quizBackup?: Quiz,
}

export const companyModule: Module<ICompanyState, IRootState> = {
    namespaced: true,
    state: {
        loadedQuizzes: undefined,
        loadingQuizzes: false,
        licenses: [],
        editQuiz: undefined,
    },
    getters: {
        loading: (state: ICompanyState) => state.loadingQuizzes,
        licenses: (state: ICompanyState) => state.licenses,
        loadedQuizzes: (state: ICompanyState) => state.loadedQuizzes,
        editQuiz: (state: ICompanyState) => state.editQuiz,
        backup: (state: ICompanyState) => state.quizBackup,
    },
    mutations: {
        toggleLoading: (state: ICompanyState) => state.loadingQuizzes = !state.loadingQuizzes,
        setQuiz: (state: ICompanyState, quiz: Quiz) => {
            state.editQuiz = quiz;
            state.quizBackup = quiz;
        },
        resetQuiz: (state: ICompanyState) => state.editQuiz = state.quizBackup,
    },
    actions: {
        fetchCompanyQuizzes: async (context: ActionContext<ICompanyState, IRootState>, page: number) => {
            context.commit("toggleLoading");
            try {
                const licenseResponse = await AdminService.getAllLicenses();
                context.state.licenses = licenseResponse.data;
            } catch (_) {
                context.state.licenses = [];
            }

            try {
                const result = await UserService.fetchCompanyQuizzes(page);
                context.state.loadedQuizzes = result.data;
            } catch (_) {
                context.state.loadedQuizzes = undefined;
            }
            context.commit("toggleLoading");
        },

        deleteQuiz: async (context: ActionContext<ICompanyState, IRootState>, id: number) => {
            context.commit("toggleLoading");
            try {
                const result = await UserService.deleteCompanyQuiz(id);

                if (context.state.loadedQuizzes) {
                    const quiz = context.state.loadedQuizzes?.data.find(q => q.id == id);
                    if (quiz) {
                        const index = context.state.loadedQuizzes?.data.indexOf(quiz, 0);
                        if (index > -1) {
                            context.state.loadedQuizzes.data.splice(index, 1);
                        }
                    }
                }
                
                const alert = {
                    title: "Fortbildung/Information wurde gelöscht",
                    message: `Die Fortbildung wurde erfolgreich gelöscht!`,
                    type: "success",
                  };
                  context.dispatch("alertModule/createAlert", alert, { root: true });
            } catch (_) {
                const alert = {
                    title: "Fortbildung/Information konnte nicht gelöscht werden.",
                    message: `Es ist ein unbekannter Fehler beim Löschen der Fortbildung aufgetreten!`,
                    type: "danger",
                  };
                  context.dispatch("alertModule/createAlert", alert, { root: true });
            }
            context.commit("toggleLoading");
        },

        loadQuestions: async (context: ActionContext<ICompanyState, IRootState>, id: number) => {
            if (context.state.editQuiz == undefined) return;

            try {
                const response = await UserService.fetchQuizQuestions(id);
                context.state.editQuiz.questions = response.data;
            } catch (_) {
                context.state.editQuiz.questions = [];
            }
        },

        saveAndEditQuiz: async (context: ActionContext<ICompanyState, IRootState>, quiz: Quiz) => {
            if (context.state.editQuiz == undefined) return;

            let alert = {
                title: "Fortbildung/Information wurde bearbeitet",
                message: `Die Fortbildung/Information wurde erfolgreich bearbeitet und ist jetzt online verfügbar!`,
                type: "success",
            };

            try {
                await UserService.editQuiz(quiz);
            } catch (_) {
                alert = {
                    title: "Fortbildung/Information",
                    message: `Beim Bearbeiten ist ein unbekannter Fehler aufgetreten!`,
                    type: "danger",
                };
            }

            context.dispatch("alertModule/createAlert", alert, { root: true });
        },
    }
}