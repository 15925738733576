<template>
  <div class="hidden xl:flex">
    <div
      class="
        absolute
        -top-10
        -left-5
        w-72
        h-72
        bg-primary-lightest
        rounded-full
        mix-blend-multiply
        filter
        blur-xl
        opacity-70
        animate-blob
      "
    ></div>
    <div
      class="
        absolute
        -top-10
        right-10
        w-72
        h-72
        bg-yellow-300
        rounded-full
        mix-blend-multiply
        filter
        blur-xl
        opacity-70
        animate-blob
        animation-delay-2000
      "
    ></div>
    <div
      class="
        absolute
        top-96
        left-40
        w-72
        h-72
        bg-red-400
        rounded-full
        mix-blend-multiply
        filter
        blur-xl
        opacity-70
        animate-blob
        animation-delay-4000
      "
    ></div>
  </div>
</template>

<style scoped lang="postcss">
@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}
</style>