import { IRootState } from "@/main";
import UserService, { User } from "@/services/UserService";
import { ActionContext, Module } from "vuex";

interface IUserState {
  user?: User;
  redirectToLicenses: boolean;
  loadingPage: boolean;
}

export const userModule: Module<IUserState, IRootState> = {
  namespaced: true,
  state: {
    user: undefined,
    redirectToLicenses: false,
    loadingPage: false,
  },
  getters: {
    getUser: (state: IUserState) => state.user,
    isLoading: (state: IUserState) => state.loadingPage,
  },
  mutations: {
    setUser: (state: IUserState, user: User) => {
      state.user = user;
    },
    setRedirectToLicense: (state: IUserState, redirect: boolean) => {
      state.redirectToLicenses = redirect;
    },
    toggleLoading: (state: IUserState) => state.loadingPage = !state.loadingPage,
  },
  actions: {
    fetchCurrentUser: async (
      context: ActionContext<IUserState, IRootState>
    ): Promise<User> => {
      const user = await UserService.fetchCurrentUser();
      context.commit("setUser", user.data);

      if (user.data != null && user.data.active_subscription != null) {
        context.commit(
          "paymentModule/setActiveSubscription",
          user.data.active_subscription,
          { root: true }
        );
      }

      return user.data;
    },
    setRedirectToLicense: (
      context: ActionContext<IUserState, IRootState>,
      redirect: boolean
    ) => {
      context.commit("setRedirectToLicense", redirect);
    },
  },
};
