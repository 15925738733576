import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex mt-8" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        class: "form-checkbox text-primary",
        checked: _ctx.checked,
        onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:checked', event.target.checked))
      }, null, 40, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}