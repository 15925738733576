<template>
  <div class="flex flex-col min-h-screen items-center">
    <div class="max-w-screen-2xl">
      <!-- Header -->
      <div
        v-if="showNavigation"
        class="
          flex-none flex flex-col
          md:flex-row
          space-x-10
          px-10
          w-full
          h-auto
          md:h-14
          items-center
          md:justify-between
        "
      >
        <div
          class="
            flex-grow
            text-2xl
            tracking-wide
            ml-2
            font-semibold
            flex
            items-center
          "
        >
          <img src="../../assets/logo.png" class="h-10 w-10 mr-5" />
          <span class="text-gray-500">1</span>
          <span class="text-primary">Minute</span>
        </div>
        <div class="flex-grow-0 flex justify-center items-center z-40">
          <div class="flex w-full justify-end content-center">
            <a
              class="
                inline-block
                text-blue-300
                no-underline
                hover:text-primary hover:text-underline
                text-center
                h-10
                p-2
                md:h-auto md:p-4
              "
              target="_blank"
              href="https://twitter.com/1MinuteApp"
            >
              <svg
                class="fill-current h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"
                ></path>
              </svg>
            </a>
            <a
              class="
                inline-block
                text-blue-300
                no-underline
                hover:text-primary hover:text-underline
                text-center
                h-10
                p-2
                md:h-auto md:p-4
              "
              target="_blank"
              href="https://www.facebook.com/1MinuteApp"
            >
              <svg
                class="fill-current h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="h-screen">
        <!--Main-->
        <div
          class="
            container
            px-6
            mx-auto
            flex flex-wrap flex-col
            md:flex-row
            items-center
            relative
          "
        >
          <div
            class="
              absolute
              hidden
              w-full
              max-w-4xl
              h-1/2
              lg:flex
              top-0
              right-0
              py-6
            "
          >
            <BluredBubbles class="h-screen max-w-lg" />
          </div>
          <!--Left Col-->
          <div
            class="
              flex flex-col
              w-full
              xl:w-2/5
              justify-center
              lg:items-start
              overflow-y-hidden
              relative
            "
          >
            <h1
              class="
                my-4
                text-2xl
                md:text-5xl
                text-primary
                font-bold
                leading-tight
                text-center
                md:text-left
                slide-in-bottom-h1
              "
            >
              The clever way to always be up to date
            </h1>
            <p
              class="
                leading-normal
                text-base
                md:text-2xl
                mb-8
                text-center
                md:text-left
                slide-in-bottom-subtitle
              "
            >
              Erhalten Sie regelmäßig die neuesten 1-Minute-Fortbildungen auf
              Ihr Smartphone oder Tablet via APP.
            </p>

            <p class="hidden md:block font-bold text-center md:text-left fade-in">
              Unsere App herunterladen:*
            </p>
            <p class="hidden md:block text-indigo-300 text-xs fade-in text-center md:text-left">
              *Nach erfolgreicher Zahlung wird Ihr Account freigeschaltet.<br />
              Sie erhalten ein Passwort per E-Mail.
            </p>
            <div
              class="flex
                flex-row
                w-full
                md:space-x-5
                justify-center
                items-center
                md:justify-start
                pb-5
                lg:pb-0
                fade-in
              "
            >
              <a
                href="https://apps.apple.com/us/app/1minute-fortbildungen/id1590579427"
                target="_blank"
                class="
                  flex
                  mt-3
                  w-48
                  h-14
                  bg-black
                  text-white
                  rounded-xl
                  items-center
                  justify-center
                  cursor-pointer
                "
              >
                <div class="mr-3">
                  <svg viewBox="0 0 384 512" width="30">
                    <path
                      fill="currentColor"
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                    />
                  </svg>
                </div>
                <div>
                  <div class="text-xs">Download im</div>
                  <div class="text-2xl font-semibold font-sans -mt-1">
                    App Store
                  </div>
                </div>
              </a>
              <a
                v-if="!isApple()"
                href="https://play.google.com/store/apps/details?id=de.u1minute.oneminute_app"
                target="_blank"
                class="
                  flex
                  mt-3
                  w-48
                  h-14
                  bg-black
                  text-white
                  rounded-lg
                  items-center
                  justify-center
                  cursor-pointer
                "
              >
                <div class="mr-3">
                  <svg viewBox="30 336.7 120.9 129.2" width="30">
                    <path
                      fill="#FFD400"
                      d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                    />
                    <path
                      fill="#FF3333"
                      d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                    />
                    <path
                      fill="#48FF48"
                      d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                    />
                    <path
                      fill="#3BCCFF"
                      d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                    />
                  </svg>
                </div>
                <div>
                  <div class="text-xs">Jetzt herunterladen</div>
                  <div class="text-xl font-semibold font-sans -mt-1">
                    Google Play
                  </div>
                </div>
              </a>
            </div>
            <div
              class="
                hidden md:flex
                md:flex-row
                items-center
                justify-center
                w-full
              "
            >
              <img
                src="../../assets/left-arrow.png"
                alt="arrow"
                class="w-28 mr-3"
              />
              <h2 class="uppercase font-medium text-center">
                14 Tage kostenlos<br />testen
              </h2>
            </div>
          </div>

          <!--Right Col-->
          <div
            class="
              w-full
              xl:w-3/5
              py-6
              overflow-y-hidden
              flex
              justify-center
              items-center
              flex-col
            "
          >
            <img
              class="lg:mr-0 slide-in-bottom w-1/3 object-scale-down"
              src="../../assets/iphonepreview.png"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 mt-10 animate-bounce md:hidden"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
          </div>

          <div
            class="
              flex-grow flex flex-col
              lg:flex-row
              items-center
              justify-between
              mt-24
            "
          >
            <div class="lg:w-1/2 w-full">
              <h1
                role="heading"
                class="
                  text-5xl
                  md:text-3xl
                  font-bold
                  leading-10
                  mt-3
                  mb-5
                  text-gray-800
                "
              >
                1-Minute-Fortbildungen
              </h1>
              <div class="grid grid-cols-2 gap-4 mt-5">
                <div class="flex flex-col space-x-2.5 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 text-primary self-center"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <h1 class="text-lg uppercase font-medium self-center">
                    ERSTKLASSIG
                  </h1>
                  <p class="text-base text-primary text-center">
                    Alle Informationen werden von Experten und Wissenschaftlern
                    recherchiert und zusammengestellt
                  </p>
                </div>

                <div class="flex flex-col space-x-2.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 text-primary self-center"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    />
                  </svg>
                  <h1 class="text-lg uppercase font-medium self-center">
                    Quellen
                  </h1>
                  <p class="text-base text-primary text-center">
                    Quellenangaben ermöglichen eine Informationsvertiefung für
                    die Nutzer
                  </p>
                </div>

                <div class="flex flex-col space-x-2.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 text-primary self-center"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                  <h1 class="text-lg uppercase font-medium self-center">
                    Immer up.to.date
                  </h1>
                  <p class="text-base text-primary text-center">
                    Rufen Sie die 1-Minuten-Forbildungen jederzeit wieder auf
                    und /oder priorisieren Sie diese ganz individuell auf Ihrem
                    Smartphone.
                  </p>
                </div>

                <div class="flex flex-col space-x-2.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 text-primary self-center"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <h1 class="text-lg uppercase font-medium self-center">
                    Fortbildungspunkte
                  </h1>
                  <p class="text-base text-primary text-center">
                    Sammeln Sie mit unseren 1-Minuten-Fortbildungen
                    Fortbildungspunkte bei unserem Kooperationspartner RgB
                    (Registrierungsstelle beruflich Pflegender).
                  </p>
                </div>
              </div>
            </div>
            <div
              class="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8"
              role="list"
            >
              <div class="flex flex-col md:flex-row space-x-5 items-center mb-10 justify-center">
                <MethodsBanner class="mt-10" />
                <div class="w-56 z-50">
                  <div
                    class="
                      bg-gray-100
                      shadow
                      rounded-full
                      flex
                      items-center
                      mt-5
                    "
                  >
                    <button
                      @click="toggle = true"
                      :class="
                        toggle === true
                          ? 'bg-primary text-white'
                          : 'text-gray-600'
                      "
                      class="
                        bg-gray-100
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-primary
                        focus:outline-none
                        text-base
                        leading-none
                        rounded-full
                        py-4
                        px-6
                        mr-1
                      "
                      id="monthly"
                    >
                      Monatlich
                    </button>
                    <button
                      @click="toggle = false"
                      :class="
                        toggle === false
                          ? 'bg-primary text-white'
                          : 'text-gray-600'
                      "
                      class="
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-primary
                        focus:outline-none
                        text-base
                        leading-none
                        text-white
                        rounded-full
                        py-4
                        px-6
                      "
                      id="annually"
                    >
                      Jährlich
                    </button>
                  </div>
                </div>
              </div>
              <img
                src="https://i.ibb.co/0n6DSS3/bgimg.png"
                class="absolute w-full right-0 top-20"
                alt="background circle images"
              />
              <div
                v-for="product in products"
                :key="product.stripePriceId"
                role="listitem"
                class="
                  bg-white
                  cursor-pointer
                  shadow
                  rounded-lg
                  mt-3
                  flex
                  relative
                  z-30
                "
              >
                <div
                  class="w-2.5 h-auto bg-primary rounded-tl-md rounded-bl-md"
                ></div>
                <div class="w-full p-8">
                  <div class="md:flex items-center justify-between">
                    <h2 class="text-2xl font-semibold leading-6 text-gray-800">
                      {{ product.name }}
                    </h2>
                    <div class="flex flex-col">
                      <p
                        class="
                          text-2xl
                          md:mt-0
                          mt-4
                          font-semibold
                          leading-6
                          text-gray-800
                        "
                      >
                        {{ product.price }}
                        <span v-if="toggle" class="font-normal text-base"
                          >/Monat</span
                        >
                        <span v-else class="font-normal text-base">/Jahr</span>
                      </p>
                      <p
                        v-if="product.savedAnnually"
                        class="font-normal text-xs"
                      >
                        {{ product.savedAnnually }} gespart
                      </p>
                    </div>
                  </div>
                  <p class="md:w-80 text-base leading-6 mt-4 text-gray-600">
                    Sie erhalten alle Fortbildungen in dem ausgewählten Bereich.
                  </p>
                  <div class="grid gid-cols-1 md:grid-cols-2 my-5 gap-y-2.5">
                    <div class="flex space-x-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <h2>
                        <span class="text-xl"><b>14</b></span> Tage
                        <b>kostenlos</b> testen
                      </h2>
                    </div>

                    <div class="flex space-x-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <h2>Jederzeit kündbar.</h2>
                    </div>

                    <div class="flex space-x-2.5 break-normal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <div class="flex flex-col">
                        <h2>Fortbildungspunkte & Zertifikate für RbP</h2>
                        <span class="text-xs font-extralight"
                          >(Registrierung beruflich Pflegender)</span
                        >
                      </div>
                    </div>

                    <div v-if="!toggle" class="flex space-x-2.5 break-normal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <div class="flex flex-col">
                        <h2>Mindesvertragslaufzeit: 12 Monate</h2>
                      </div>
                    </div>
                    <div v-else class="flex space-x-2.5 break-normal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-300"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <div class="flex flex-col">
                        <h2>Abrechnungszeitraum: monatlich</h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between items-center mt-2.5">
                    <a
                      v-if="!showRegister"
                      @click="showRegistration(product.stripePriceId)"
                      class="
                        self-end
                        place-self-end
                        p-2.5
                        border border-transparent
                        text-xs
                        font-medium
                        rounded-full
                        text-white
                        bg-primary
                        shadow-md
                        hover:shadow-xl hover:bg-primary-dark
                        focus:outline-none
                        transition
                        duration-150
                        ease-in-out
                      "
                    >
                      Auswählen & registrieren
                    </a>
                    <register-private-account
                      :show="showRegister"
                      :error="errorOccured"
                      :message="errorMessage"
                      @submit="fetchLink"
                    />
                  </div>
                  <p class="text-xs font-light mt-4">
                    *Die Preisangabe bezieht sich auf die monatlichen Kosten
                    inkl. gesetzlicher Mehrwertsteuer.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div
            class="
              flex-grow flex flex-col
              mt-56
              space-x-10
              px-10
              py-10
              w-full
              bg-white
              items-end
              justify-end
            "
          >
            <div class="flex space-x-5">
              <div class="flex flex-col">
                <h3 class="font-semibold">1Minute &copy; 2021</h3>
                <router-link
                  :to="{ name: 'imprint' }"
                  target="_blank"
                  class="text-sm font-light cursor-pointer hover:underline"
                >
                  Impressum
                </router-link>
                <router-link
                  :to="{ name: 'privacy' }"
                  target="_blank"
                  class="text-sm font-light cursor-pointer hover:underline"
                >
                  Datenschutz
                </router-link>
                <router-link
                  :to="{ name: 'agb' }"
                  target="_blank"
                  class="text-sm font-light cursor-pointer hover:underline"
                >
                  AGB
                </router-link>
              </div>
              <div
                class="
                  rounded-full
                  flex
                  justify-center
                  items-center
                  bg-primary
                  h-14
                  w-14
                "
              >
                <img :src="FooterHand" class="w-20 h-20 absolute" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";

import FooterHand from "@/assets/rockstar_hands.svg";
import { privateProducts } from "@/utils/stripe_products";
import MethodsBanner from "../../components/payments/MethodsBanner.vue";
import BluredBubbles from "@/components/frontend/BluredBubbles.vue";
import RegisterPrivateAccount from "@/components/payments/RegisterPrivateAccount.vue";
import ApiDataService, {
  RegisterPrivateAccountDTO,
} from "@/services/ApiDataService";
import request from "axios";
import { useMeta } from "vue-meta";
export default defineComponent({
  props: {
    showNavigation: {
      type: Boolean,
      default: true,
    },
  },
  components: { MethodsBanner, BluredBubbles, RegisterPrivateAccount },
  setup() {
    const toggle = ref<boolean>(true);
    const showRegister = ref<boolean>(false);
    const errorOccured = ref<boolean>(false);
    const errorMessage = ref<string>("");

    useMeta({
      title: "1Minute - Privat - 1Minute Fortbildungen",
      description:
        "Erhalten Sie regelmäßig die neuesten 1-Minute-Fortbildungen auf Ihr Smartphone oder Tablet via APP.",
      link: {
        rel: "canonical",
        href: "https://1minute.app/private",
      },
    });

    const isApple = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      const is_uiwebview = /iPad|iPhone|iPod/.test(userAgent);
      return is_uiwebview;
    }

    const products = computed(() =>
      toggle.value ? privateProducts.monthly : privateProducts.annually
    );

    watch(
      () => toggle.value,
      () => {
        showRegister.value = false;
        errorMessage.value = "";
        errorOccured.value = false;
      }
    );

    const selectedProductId = ref("");

    const showRegistration = (selectedProduct: string) => {
      showRegister.value = true;
      selectedProductId.value = selectedProduct;
    };

    const fetchLink = async (registerUserDTO: RegisterPrivateAccountDTO) => {
      registerUserDTO.stripe_id = selectedProductId.value;

      try {
        const response = (
          await ApiDataService.getCheckoutLinkPrivateUser(registerUserDTO)
        ).data;

        const link = document.createElement("a");
        link.href = response.url;
        link.click();
      } catch (e) {
        if (request.isAxiosError(e) && e.response) {
          if (Array.isArray(e.response.data)) {
            const errors = e.response.data.map((error) => error).join(" ");
            errorMessage.value = errors;
          } else {
            errorMessage.value = e.response.data;
          }
        }
        errorOccured.value = true;
      }
    };

    return {
      FooterHand,
      toggle,
      products,
      fetchLink,
      showRegister,
      showRegistration,
      errorOccured,
      errorMessage,
      isApple,
    };
  },
});
</script>
<style scoped lang="postcss">
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-bottom-h1 {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.5s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
}
.slide-in-bottom-subtitle {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.75s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s
    both;
}
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}
.bounce-top-icons {
  -webkit-animation: bounce-top 0.9s 1s both;
  animation: bounce-top 0.9s 1s both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
