import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col py-4 space-y-4 z-10"
}
const _hoisted_2 = {
  class: "bg-transparent text-center lg:px-4",
  id: "info"
}
const _hoisted_3 = {
  class: "p-2 bg-background-mat-primary shadow-md items-center text-white leading-none lg:rounded-full flex lg:inline-flex",
  role: "alert"
}
const _hoisted_4 = { class: "flex rounded-full bg-yellow-100 text-black uppercase px-2 py-1 text-xs font-bold mr-3 tracking-wider" }
const _hoisted_5 = { class: "font-light mr-2 text-left flex-auto flex flex-col" }
const _hoisted_6 = {
  key: 0,
  class: "flex rounded-full bg-primary text-white uppercase px-2 py-1 text-xs font-bold mr-3 tracking-wider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "duration-700 ease-out",
    "enter-from-class": "transform opacity-0 scale-75",
    "enter-to-class": "opacity-100 scale-100",
    "leave-active-class": "duration-200 ease-in",
    "leave-from-class": "opacity-100 scale-100",
    "leave-to-class": "transform opacity-0 scale-75",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "title")
                ]),
                _createElementVNode("p", _hoisted_5, [
                  _createElementVNode("span", null, [
                    _renderSlot(_ctx.$slots, "default")
                  ])
                ]),
                (_ctx.hasActionSlot)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "action")
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}