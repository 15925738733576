import http from "@/modules/http-common";
import {
  ICreateProductDTO,
  ICreateShopDTO,
  IEditShopDTO,
  Shop,
  ShopItem,
  ShopItemPurchase,
} from "@/types/shop";
import UserService from "./UserService";

class ShopService {
  async fetchShop(company_id: number) {
    const _config = {
      headers: { Authorization: `Bearer ${UserService.getAccessToken()}` },
    };
    const response = await http.get<Shop>(`shop/${company_id}`, _config);
    return response;
  }

  async createShop(dto: ICreateShopDTO) {
    const _config = {
      headers: {
        Authorization: `Bearer ${UserService.getAccessToken()}`,
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("company_id", dto.company_id!.toString());
    formData.append("name", dto.name);
    formData.append("file", dto.file!);

    return http.post<Shop>(`shop`, formData, _config);
  }

  async createProduct(dto: ICreateProductDTO) {
    const _config = {
      headers: {
        Authorization: `Bearer ${UserService.getAccessToken()}`,
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("shop_id", dto.shop_id!.toString());
    formData.append("name", dto.name);
    formData.append("description", dto.description);
    formData.append("real_price", dto.real_price!.toString());
    formData.append("points_price", dto.points_price!.toString());
    formData.append("file", dto.file!);

    return http.post<ShopItem>(`shop/product`, formData, _config);
  }

  async editShop(dto: IEditShopDTO) {
    const _config = {
      headers: {
        Authorization: `Bearer ${UserService.getAccessToken()}`,
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("shop_id", dto.shop_id.toString());
    formData.append("company_id", dto.company_id!.toString());
    formData.append("name", dto.name);
    formData.append("file", dto.file!);
    formData.append("_method", "PUT");

    return http.post<Shop>(`shop`, formData, _config);
  }

  async deleteProduct(id: number) {
    const _config = {
      headers: { Authorization: `Bearer ${UserService.getAccessToken()}` },
    };
    return http.delete<boolean>(`shop/product/${id}`, _config);
  }

  async fetchPendingPurchases(id: number) {
    const _config = {
      headers: { Authorization: `Bearer ${UserService.getAccessToken()}` },
    };
    return http.get<Array<ShopItemPurchase>>(`shop/${id}/purchases`, _config);
  }

  async updatePurchaseStatus(id: number) {
    const _config = {
      headers: { Authorization: `Bearer ${UserService.getAccessToken()}` },
    };
    return http.put(`shop/product/${id}`, { status: true }, _config);
  }

  async updateShopProduct(id: number, product: ICreateProductDTO) {
    const _config = {
      headers: {
        Authorization: `Bearer ${UserService.getAccessToken()}`,
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("real_price", product.real_price!.toString());
    formData.append("points_price", product.points_price!.toString());
    if (product.file)
      formData.append("file", product.file!);
    formData.append("_method", "PUT");

    return http.post<ShopItem>(`shop/product/edit/${id}`, formData, _config);
  }
}

export default new ShopService();
