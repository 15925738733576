import UserService from "@/services/UserService";
import axios from "axios";

interface ServerToken {
  access_token: string;
  expires_in: number;
  token_type: string;
}

const serverUrl = "https://api.henrikthien.de";
// const serverUrl = "http://127.0.0.1:8000";

export const assetUrl = `${serverUrl}/storage/quiz_assets`;

const axiosInstance = axios.create({
  baseURL: serverUrl + "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function refreshAccessToken(): Promise<string> {
  const oldToken = UserService.getAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${oldToken}` },
  };

  const response = await axiosInstance.post<ServerToken>(
    "/refresh",
    null,
    config
  );

  if (response.status == 200) {
    localStorage.setItem("access_token", response.data.access_token);
    return response.data.access_token;
  }

  return "";
}

export default axiosInstance;
